import React from 'react'

export const PriceOptionSm = ({selectedPriceOption, selectedPriceOptionHandler}) => {

    const options = [
        { value: 'any', label: 'Any Price' },
        { value: '25', label: 'Under $25' },
        { value: '25-50', label: '$25 - $50' },
        { value: '50-100', label: '$50 - $100' },
        { value: '100-200', label: '$100 - $200' },
        { value: '200-10000', label: '$200 & above' }
      ];

      const handleOptionClick = (option) => {
        selectedPriceOptionHandler(option.label);
      };

  return (
    <div className='price-container container-fluid py-1'>
    <div className='row'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto price-div'>

    <ul className="price-dropdown-options">
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => handleOptionClick(option)}
            className={(selectedPriceOption.toLowerCase()===option.label.toLowerCase())?`selected-price-option`:`price-dropdown-option`}
          >
           { <span className='option-value'><span className='value'>{option.label}</span></span> }
          </li>
        ))}
      </ul>

      </div>

        </div>
        </div>
  )
}
