import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { NoProduct } from '../default/NoProduct';

export const ShowMore = ({pageNum,setPageNum,numOfItems,pageLink,forValue,ctgValue,price,setIsMoreClicked,sorted}) => {

  const totalItems = numOfItems;
  const itemsPerPage = 16;

  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const navigate = useNavigate();
  const [page, setPage] = useState(2);


  const handleClick = (event) => {

    if(page>numberOfPages){
      event.preventDefault();
      return;
     }
         
      setPageNum(page);

      if(pageNum<numberOfPages){
       setIsMoreClicked(true);

      const nextPage = page + 1;
      setPage(nextPage);

       if(sorted && sorted!=='s1')
       navigate(`/s/${forValue}/${ctgValue}?price=${price}&filter=${sorted}&page=${page}`);
       else
       navigate(`/s/${forValue}/${ctgValue}?price=${price}&page=${page}`);
      }
  
  };


  return (

    <MoreContainer>


    <div className='container'>
    <div className='row'>

      { (pageNum<=numberOfPages) ? <React.Fragment>
        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto total-count text-center mt-4'>
             { (pageNum) && <span className='total'>Showing <span className='current-page'>{pageNum}</span> - {numberOfPages} of {totalItems} total</span> }
        </div>

        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto line-div text-center'>
        <label className='line'></label>
        </div>

        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto total-count text-center mt-2'>
            <Link to={`/s/${forValue}/${ctgValue}?${sorted && sorted !== 's1' ? `price=${price}&filter=${sorted}&page=${page}` : `price=${price}&page=${page}`}`} className='show-more' onClick={handleClick}>Show More</Link>
            {/* <Link to={`/collections/${pageLink}?page=${page}`} className='show-more' onClick={handleClick}> <CircularProgress className="load-icon" size={15} /> </Link> */}
        </div>
        </React.Fragment> : <NoProduct/> }

        
        </div>
        </div>

        </MoreContainer>
  )
}

const MoreContainer = styledComponents.div`


.total{
    position: relative;
    font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    color: var(--lightGrey2);

    // .current-page{
    //   color: var(--lightBlack);
    // }
}

.line {
    position: relative;
    // margin-top:0.5rem;
    top:-0.5rem;
    width: 200px;
    height: 2px;
    background-color: var(--lightGrey); 
  }

 .show-more{

border:none;
border-radius:1.5rem;
padding: 0.5rem 6rem 0.4rem 6rem;
font-size:1rem;
outline:none;
background: #ffffff;
color: var(--pureBlack);
border: 1px solid var(--pureBlack);
cursor:pointer;
// font-family: 'Karla', sans-serif;
font-family: 'Open Sans', sans-serif; 
font-weight:bold;
text-transform: uppercase;
letter-spacing:0.05rem;
transition: all 0.6s ease;
text-decoration:none;

&:hover{
    background: var(--lightBlack);
    color: #ffffff;
}

 } 


 .load-icon{
    color: #ffffff;
    position: relative;
}

`;