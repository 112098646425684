import React from 'react';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt} from '@fortawesome/free-solid-svg-icons';

export const Title = ({title}) => {
  return (
    <TitleContainer>
      <div className='offer-title'>
       <h2 className='title text-capitalize'>{title} <FontAwesomeIcon className="fa-fire icon" icon={faFireAlt}/> </h2>
       </div>
    </TitleContainer>
  )
}

const TitleContainer = styledComponents.div`

.offer-title{
  position:absolute;
  margin-left:1.4rem;
}

@media (max-width: 600px) {
  .offer-title{
    margin-left:1rem;
  }
}

.title{
    position: relative;
    color: var(--pureBlack);
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
}

.icon{
    font-size:1.4rem;
    margin-left:0.1rem;
    color: var(--lightOrange);
   }


`;