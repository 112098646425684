import React,{useState,useEffect,useRef} from 'react';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';

export const ModalSm = () => {

  const location = useLocation();
  const destParam = new URLSearchParams(location.search).get('dest');
  const dest = destParam ? destParam.charAt(0).toUpperCase() + destParam.slice(1) : null;
  const prodLink = new URLSearchParams(location.search).get('prod_link');


    const [colors, setColors] = useState(['#5555df', '#e0dddd', '#e0dddd']); // Initial colors for the icons
    const iconCount = colors.length;

    // useEffect(()=>{

    //   if (prodLink) {
    //     setTimeout(() => {
    //       return window.location.href=prodLink;
    //     }, 5000);
    //   }
  
    // },[prodLink]);
  
    const countRef = useRef(0);

    useEffect(() => {

      axios.get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        const country = data.country_name;
        trackAffiliateOffer('JS102', 'Sex Toy Affiliate', 'PPL',dest, prodLink, country);
        })
      .catch((error) => {
        console.error('Error fetching country:', error);
        trackAffiliateOffer('JS102', 'Sex Toy Affiliate', 'PPL', dest, prodLink,'');
      });

      const interval = setInterval(() => {
        countRef.current += 1;
  
        if (countRef.current === 6) {
          clearInterval(interval);
          return window.location.href=prodLink;
        }
  
        const newColors = colors.map((color, index) =>
          index <= Math.floor((Date.now() / 1000) % iconCount) ? '#5555df' : '#e0dddd'
        );
  
        setColors(newColors);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []); 

    const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

      const res = axios.get(`https://hutils.loxal.net/whois`);
      const ip = (await res).data.ip;
    
      let device="undetected";
    
      if (isMobile)
       device="mobile";
    
      if (isBrowser)
       device="desktop";
      
    
      const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
        affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
    
        const save_url=process.env.REACT_APP_AFFILIATE;
    
        axios.post(`${save_url}`,detail,{
            headers:{
                'Content-Type':'application/json',
             //   'Authorization':'bearer '.concat(token)
            }
        });
    
    }

    const spanStyle = {
      maxWidth: '20ch',
      display: 'inline-block',
      overflowWrap: 'break-word',
    };

    const spanStyle2 = {
      maxWidth: '20ch',
      display: 'inline-block',
      overflowWrap: 'break-word',
    };


  return (
    <ModalContainer>
         <div className='container'>
                 <div className="row">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 item-found text-center">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 title-div text-start">
                        <h2 className='title'>Thank you, almost there...</h2>
                    </div>

                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 redirect-div">

                      <div className='row'>
 
                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 scanner-div d-flex">

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 img-div">

                    <img src='/toyscanner.svg' alt="Toysacnner" width={'90rem'} height={'90rem'} className="logo-svg align-top"/>
                    <h3 className='site-name'>Toyscanner</h3>
                    </div>

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 text-div">

                    <span className='desc-01' style={spanStyle}>You've found your product</span>

                     </div>

                    </div>

                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 icon-div d-flex">

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-7 arrow-div">

                      <div className='arrow' style={{ display: 'flex', flexDirection: 'column' }}>
                    <FontAwesomeIcon className={`angle icon-01`} icon={faAngleDown} style={{ color: colors[0] }} />
      <FontAwesomeIcon className={`angle icon-02`} icon={faAngleDown} style={{ color: colors[1] }} />
      <FontAwesomeIcon className={`angle icon-03`} icon={faAngleDown} style={{ color: colors[2] }} />
                       </div>
                  </div>

                  <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 text-div">
      { (dest) && <span className='desc-02' style={spanStyle}>We're taking you to {dest}</span> }
                    </div>
                    </div>

                    { (dest) && <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 brand-img-div d-flex">

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 brand-div">
                    <h3 className='brand-name'>{dest}</h3>
                    </div>

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 text-div">
                    <span className='desc-03' style={spanStyle2}>Now complete your shopping with {dest}</span>
                    </div>

                    </div> }

                    </div>

                    </div>
                    

                   </div>{/*item-found*/}

                </div>
           </div>
       </ModalContainer>
  )
}

const ModalContainer = styledComponents.div`

.item-found{
    background: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    margin-top:4rem;
    padding-bottom:3rem;
    padding-top:1rem;
}

.redirect-div{
  margin-top:1.5rem;
}



.title{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: var(--pureBlack);
}

.angle{
    font-size:2rem;
    margin-right: 0.3rem;
}



.site-name{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: var(--lightBlue5);
  position: relative;
  top:-2rem;
}


.brand-name{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.3rem;
  color: var(--lightGrey2);
  margin-top: 2.8rem;

}


.desc-01{
  font-family: 'Roboto', sans-serif;
  font-size:0.9rem;
  color: var(--pureBlack);
  margin-top:1.8rem;
  margin-left: 1.5rem;
}

.desc-02{
  font-family: 'Roboto', sans-serif;
  font-size:0.9rem;
  color: var(--pureBlack);
  margin-top:1.4rem;
  margin-left: 1.5rem;
}

.desc-03{
  font-family: 'Roboto', sans-serif;
  font-size:0.9rem;
  color: var(--pureBlack);
  margin-top:1.8rem;
  margin-left: 1.5rem;
}



`;