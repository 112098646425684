import React,{useState,useEffect,useRef} from 'react';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';

export const Modal = () => {

  const location = useLocation();
  const destParam = new URLSearchParams(location.search).get('dest');
  const dest = destParam ? destParam.charAt(0).toUpperCase() + destParam.slice(1) : null;
  const prodLink = new URLSearchParams(location.search).get('prod_link');


    const [colors, setColors] = useState(['#5555df', '#e0dddd', '#e0dddd']); // Initial colors for the icons
    const iconCount = colors.length;

    // useEffect(()=>{

    //   if (prodLink) {
    //     setTimeout(() => {
    //       return window.location.href=prodLink;
    //     }, 5000);
    //   }
  
    // },[prodLink]);

    const countRef = useRef(0);

    useEffect(() => {
  
      const interval = setInterval(() => {
        countRef.current += 1;
  
        if (countRef.current === 6) {
          clearInterval(interval);
          return window.location.href=prodLink;
        }
  
        const newColors = colors.map((color, index) =>
          index <= Math.floor((Date.now() / 1000) % iconCount) ? '#5555df' : '#e0dddd'
        );
  
        setColors(newColors);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []); 
  
    const spanStyle = {
      maxWidth: '20ch',
      display: 'inline-block',
      overflowWrap: 'break-word',
    };

    const spanStyle2 = {
      maxWidth: '25ch',
      display: 'inline-block',
      overflowWrap: 'break-word',
    };


  return (
    <ModalContainer>
         <div className='container'>
                 <div className="row">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 item-found text-center">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 title-div text-start">
                        <h2 className='title'>Thank you, almost there...</h2>
                    </div>

                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 redirect-div">

                      <div className='row'>
 
                    <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 scanner-div">
                    <img src='/toyscanner.svg' alt="Toysacnner" width={'100rem'} height={'100rem'} className="logo-svg align-top"/>
                    <h3 className='site-name'>Joyscanner</h3>
                    <span className='desc-01' style={spanStyle}>You've found your product</span>
                    </div>

                    <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 icon-div">
                      <div className='arrow'>
                    <FontAwesomeIcon className={`angle icon-01`} icon={faAngleRight} style={{ color: colors[0] }} />
      <FontAwesomeIcon className={`angle icon-02`} icon={faAngleRight} style={{ color: colors[1] }} />
      <FontAwesomeIcon className={`angle icon-03`} icon={faAngleRight} style={{ color: colors[2] }} />
      { (dest) && <span className='desc-02' style={spanStyle}>We're taking you to {dest}</span> }
                    </div>
                    </div>

                    { (dest) && <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 brand-img-div">
                    <h3 className='brand-name'>{dest}</h3>
                    <span className='desc-03' style={spanStyle2}>Now complete your shopping with {dest}</span>
                    </div> }

                    </div>

                    </div>
                    

                   </div>{/*item-found*/}

                </div>
           </div>
       </ModalContainer>
  )
}

const ModalContainer = styledComponents.div`

.item-found{
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    margin-top:4rem;
    padding-bottom:3rem;
    padding-top:1rem;
}

.redirect-div{
  margin-top:1.5rem;
}

.scanner-div{
  position: relative;
  right: 2rem;
}

.title{
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
  color: var(--pureBlack);
}

.angle{
    font-size:3rem;
}

.site-name{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  color: var(--lightBlue5);
  position: relative;
  top:-2rem;
}

.brand-name{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.8rem;
  color: var(--lightGrey2);
  position: relative;
  top:2.4rem;
  right: 4rem;
}

.arrow{
  position: relative;
  top: 2rem;
  right:4.2rem;
}

.desc-01{
  font-family: 'Roboto', sans-serif;
  font-size:1rem;
  color: var(--pureBlack);
}

.desc-02{
  font-family: 'Roboto', sans-serif;
  font-size:1rem;
  color: var(--pureBlack);
  position: relative;
  top: 3.6rem;
}

.desc-03{
  font-family: 'Roboto', sans-serif;
  font-size:1rem;
  color: var(--pureBlack);
  position: relative;
  top: 5.9rem;
  right: 3.8rem;
}

`;