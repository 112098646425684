import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router';
import styledComponents from 'styled-components';
import { HomeButton } from './HomeButton';

export const NotFound = () => {

    const navigate=useNavigate();

    const redirectToHome=()=>{
       return navigate('/');
    }
    
  return (
    <ErrorContainer>
         <div className='container'>
                 <div className="row">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 not-found text-center my-5">
                          <p className="code">404</p>
                          <p className="msg">
                              <span className="h-msg">Oops!, sorry we couldn't find that page!</span>
                                 <br/>
                            <span className="s-msg">Either something went wrong or the page doesn't exist anymore.</span>
                          </p>

                         <div className="home-btn my-4">
                            <HomeButton onClick={()=>redirectToHome()}
                            >Back to Home</HomeButton>
                         </div>

                   </div>{/*not-found*/}

                </div>
           </div>
       </ErrorContainer>
  )
}

const ErrorContainer = styledComponents.div`

background: #ffffff;

.not-found{

    .code{
        font-size:5.5rem;
        font-family: 'Open Sans', sans-serif;
        font-weight:bold;
        color: var(--lightGrey2);
    }

    .h-msg{
        font-size:1.2rem;
        color: var(--mainDark2);
        font-family: 'Open Sans', sans-serif;
    }

    .s-msg{
        font-size:0.9rem;
        color: var(--mainDark2);
        font-family: 'Open Sans', sans-serif;
    }
}

`;