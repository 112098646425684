import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVenus } from '@fortawesome/free-solid-svg-icons';
import { faMars } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export const ForOption = ({selectedForOption,selectedForOptionHandler}) => {


    const options = [
        { value: 'her', label: 'Her' },
        { value: 'him', label: 'Him' },
        { value: 'couple', label: 'Couple' },
      ];

      const handleOptionClick = (option) => {
        selectedForOptionHandler(option.value);
      };

  return (
    <div className='container py-1'>
    <div className='row'>
    <ul className="custom-dropdown-options">
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => handleOptionClick(option)}
            className={(selectedForOption.toLowerCase()===option.label.toLowerCase())?`selected-for-option`:`custom-dropdown-option`}
          >
           { (option.value==='her') && <span className='option-value'><FontAwesomeIcon icon={faVenus} className="for-icon" /><span className='value ps-2'>{option.label}</span></span> }
            { (option.value==='him') && <span className='option-value'><FontAwesomeIcon icon={faMars} className="for-icon" /><span className='value ps-2'>{option.label}</span></span> }
            { (option.value==='couple') && <span className='option-value'><FontAwesomeIcon icon={faHeart} className="for-icon" /><span className='value ps-2'>{option.label}</span></span> }
          </li>
        ))}
      </ul>
      </div>
    </div>
  )
}
