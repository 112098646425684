import React,{useState} from 'react'

export const CtgOptionSm = ({selectedCtgOption, selectedCtgOptionHandler}) => {

    const [categories] = useState({
        'Sex Toys': ['Anal Sex Toys', 'Catheters & Sounds', 'Dildos', 'Enema Products','Harnesses',
        'Male Masturbators','Nipple Toys','Penis Rings','Pumps & Enlargers','Machines & Devices','Sex Toys Cleaning','Vibrators'],
        'Safe Sex': ['Lubricants','Condoms'],
        'Sex Dolls': [],
        'Sexual Enhancers': [],
        'Jewelry': [],
        'Lingerie':[],
        'Sex Furniture' : [],
        'Novelties': [],
        'Bondage Gear & Accessories':[]
      });

      const [selectedCategory, setSelectedCategory] = useState(null);

      const handleCategoryChange = (category) => {

        if(category.toLowerCase()==='sex toys' || category.toLowerCase()==='safe sex')
        return;
        selectedCtgOptionHandler(category);
      };

  return (
    <div className='ctg-container container-fluid py-1'>
    <div className='row'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto ctg-div'>

    <ul className="ctg-dropdown-options">
      {Object.keys(categories).map((category) => (
        <li key={category} className={(selectedCtgOption.toLowerCase()===category.toLowerCase())?`selected-ctg-dropdown-option ${category.toLowerCase() !== 'sex toys' && category.toLowerCase() !== 'safe sex' && 'single-selected-ctg-dropdown-option'}`:`ctg-dropdown-option ${category.toLowerCase() !== 'sex toys' && category.toLowerCase() !== 'safe sex' && 'single-ctg-dropdown-option'}`} onClick={()=>handleCategoryChange(category)}>
          <span className='ctg'>{category}</span>
          {categories[category].length > 0 && (
            <ul className='ctg-value-dropdown-options'>
              {categories[category].map((subCategory) => (
                <li key={subCategory} className={(selectedCtgOption.toLowerCase()===subCategory.toLowerCase())?`selected-ctg-option`:'ctg-value-dropdown-option'} onClick={()=>handleCategoryChange(subCategory)}><span className='ctg-value'>{subCategory}</span></li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>

    </div>

        </div>
        </div>
  )
}
