import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { Selling } from './best-selling/Selling';
import { Release } from './new-releases/Releases';
import { Offers } from './offers/Offers';
import { TopToyBar } from './top-bar/TopToyBar';
import { UnderOffer } from './under25/UnderOffer';
import {TopRated} from './top-rated/TopRated';
import { Footer } from './footer/Footer';
import { Helmet } from 'react-helmet';

export const ToyIndex = () => {

  const [pageTitle, setPageTitle] = useState(`Joyscanner | Find the Best Sex Toys from Trusted Brands and Sellers: Save Time, Save Money!: Vibrators, Anal Sex Toys, Penis Rings, Male Masturbators, Dildos, Condoms, Women's Lingerie & More`);
  const [pageDescription, setPageDescription] = useState(`Online shopping for Sexual Wellness & Adult Toys from a great selection of Vibrators, Anal Sex Toys, Penis Rings, Dildos, Male Masturbators, Pumps & Enlargers & more at everyday low prices from trusted brands and sellers.`);

  useEffect(()=>{

    window.scrollTo(0, 0);

    const title = `Joyscanner | Find the Best Sex Toys from Trusted Brands and Sellers: Save Time, Save Money!: Vibrators, Anal Sex Toys, Penis Rings, Male Masturbators, Dildos, Condoms, Women's Lingerie & More`;

    const desc = `Online shopping for Sexual Wellness & Adult Toys from a great selection of Vibrators, Anal Sex Toys, Penis Rings, Dildos, Male Masturbators, Pumps & Enlargers & more at everyday low prices from trusted brands and sellers.`;
    
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;
    document.body.style.zoom="100%";
   
  },[]);

  return (
    <ToyIndexContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

<div className='container-fluid'>
            <div className='row'>
                 
            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-toy-bar-div'>
           <TopToyBar/>
           </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto offers-div'>
                <Offers/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto under-div'>
                 <UnderOffer/>
             </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto release-div'>
               <Release/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-rated-div'>
                  <TopRated/>
             </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto selling-div'>
                <Selling/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
             <Footer/>
           </div>

             </div>
</div>
  
    </ToyIndexContainer>
  )
}

const ToyIndexContainer = styledComponents.div`

background-color: #ffffff;

.top-toy-bar-div{
  margin:0;
  padding:0;  
  background: var(--lightPink);
  // background: rgba(202, 26, 149, 0.5);
}

@media (max-width: 912px) {
  .top-toy-bar-div{
    padding: 0 1rem;
  }
}

@media (max-width: 820px) {
  .top-toy-bar-div{
    padding: 0 0.3rem;
  }
}

.top-bar-div{
  margin:0;
  padding:0;  
  margin-top: 0.5rem;
}

.search-bar-div{
  padding: 0 6.7rem;
}

@media (max-width: 1368px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 912px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 940px) {
  .search-bar-div{
    padding: 0 0.8rem;
  }
}

@media (max-width: 600px) {
  .search-bar-div{
    padding: 0 0.5rem;
  }
}


@media (min-width: 1024px) {
  .top-bar-div{
    margin-top: 2rem;
  }
}

.offers-div{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .offers-div{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .offers-div{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .offers-div{
    padding:0 0.1rem;  
  }
}

@media (max-width: 600px) {
  .offers-div{
    border-bottom: 3px solid var(--lightGrey3);
    padding-bottom: 1.5rem;
  }
}

.release-div{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .release-div{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .release-div{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .release-div{
    padding:0 0.1rem;  
  }
}

@media (max-width: 600px) {
  .release-div{
    border-bottom: 3px solid var(--lightGrey3);
    padding-bottom: 1.5rem;
  }
}


.selling-div{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .selling-div{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .selling-div{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .selling-div{
    padding:0 0.1rem;  
  }
}

.under-div{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .under-div{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .under-div{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .under-div{
    padding:0 0.1rem;  
  }
}

@media (max-width: 600px) {
  .under-div{
    border-bottom: 3px solid var(--lightGrey3);
    padding-bottom: 1.5rem;
  }
}

.top-rated-div{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .top-rated-div{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .top-rated-div{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .top-rated-div{
    padding:0 0.1rem;  
  }
}

@media (max-width: 600px) {
  .top-rated-div{
    border-bottom: 3px solid var(--lightGrey3);
    padding-bottom: 1.5rem;
  }
}

.footer-div{
  margin:3.5rem 0 0 0;
  padding:0;  
  background: var(--lightPink);
}

 
`;