import React from 'react'
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components'

export const Title = ({title,page}) => {
  return (
    <TitleContainer>
      <div className='offer-title'>
       <h2 className='title text-capitalize'>{title} <Link to={`/collections/${page}`} className='more'>See More</Link></h2>
       </div>
    </TitleContainer>
  )
}

const TitleContainer = styledComponents.div`

.offer-title{
  position:absolute;
  margin-left:1.4rem;
}

@media (max-width: 600px) {
  .offer-title{
    margin-left:1rem;
  }
}

.title{
    position: relative;
    color: var(--pureBlack);
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
}

.more{
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  font-size: 0.9rem;
  color: var(--lightBlue4);
  margin-left: 0.6rem;
  text-decoration: none;
  cursor: pointer;
  transition: text-decoration 0.3s, color 0.3s;

  &:hover{
    text-decoration: underline;
  }
}

`;