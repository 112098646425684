import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { Footer } from '../footer/Footer';
import { TopToyBar } from '../top-bar/TopToyBar';
import { Treanding } from '../treanding/Treanding';
import { BrandList } from './BrandList';
import { Helmet } from 'react-helmet';

export const BrandPage = () => {

  const [pageTitle, setPageTitle] = useState(`Top Sex Toy Brands - Joyscanner`);
  const [pageDescription, setPageDescription] = useState(`Discover the top Sex Toy Brands. Find the top 100 most popular Sex Toy Brands in Joyscanner.`);

  useEffect(()=>{
    const title = `Top Sex Toy Brands - Joyscanner`;
    document.title = title;
 },[]);

  return (

    <BrandContainer>

      <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>


        <div className='container-fluid'>
    <div className='row'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-toy-bar-div'>
           <TopToyBar/>
           </div>

           <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto brand-page'>
                <BrandList/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto treanding-div'>
               <Treanding/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
             <Footer/>
           </div>
            
        </div>
        </div>
        </BrandContainer>
  )
}

const BrandContainer = styledComponents.div`

background-color: #ffffff;

.top-toy-bar-div{
  margin:0;
  padding:0;  
  background: var(--lightPink);
}

@media (max-width: 912px) {
  .top-toy-bar-div{
    padding: 0 1rem;
  }
}

@media (max-width: 820px) {
  .top-toy-bar-div{
    padding: 0 0.3rem;
  }
}

.search-bar-div{
  padding: 0 6.7rem;
}

@media (max-width: 1368px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 912px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 940px) {
  .search-bar-div{
    padding: 0 0.8rem;
  }
}

@media (max-width: 600px) {
  .search-bar-div{
    padding: 0 0.5rem;
  }
}

.brand-page{
  margin: 1.8rem 0 0 0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .brand-page{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .brand-page{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .brand-page{
    padding:0 0.1rem;  
  }
}


.top-bar-div{
  margin:0;
  padding:0;  
  margin-top: 0.5rem;
}


@media (min-width: 1024px) {
  .top-bar-div{
    margin-top: 2rem;
  }
}

.treanding-div{
  margin-top:0.2rem;
  padding:0;  
  background-color: #ffffff;
}

.footer-div{
  margin:3.5rem 0 0 0;
  padding:0;  
  background: var(--lightPink);
}

`;