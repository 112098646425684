import React from 'react'
import { Link } from 'react-router-dom'

export const Item = ({item}) => {
    
  return (
    <div className="container">
    <div className="row">
          <div className="col-12 col-md-12 mx-auto col-lg-12 col-sm-12 item">
              <div className="item-name">
              <Link className="name">{item}</Link>
              </div>
          </div>
    </div>
  </div>
  )
}
