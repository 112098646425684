import React from 'react'
import styledComponents from 'styled-components'

export const PageTitle = ({title,ctg}) => {
  return (
    <TitleContainer>
      <div className='offer-title d-none d-md-block'>
       <h2 className='title text-capitalize'>{title} <span className='new-offer ms-1'>{`( ${ctg} )`}</span></h2>
       </div>
       <div className='offer-title d-md-none'>
       <h2 className='title-sm text-capitalize'>{title} <span className='new-offer-sm ms-1'>{`( ${ctg} )`}</span></h2>
       </div>
    </TitleContainer>
  )
}

const TitleContainer = styledComponents.div`

.offer-title{
  position:relative;
  margin-left:1.45rem;
}

@media (max-width: 600px) {
  .offer-title{
    margin-left:0.95rem;
    // top:0.3rem;
  }
}

.title{
    position: relative;
    color: var(--pureBlack);
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;

    .new-offer{
      color: var(--lightGrey2);
      font-size: 1.2rem;
    }
}

.title-sm{
  position: relative;
  color: var(--pureBlack);
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.4rem;

  .new-offer-sm{
    color: var(--lightGrey2);
    font-size: 1rem;
  }
}


`;