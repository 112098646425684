import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


export const ItemSm = () => {

  return (
     <React.Fragment>
       
            <div className='col-xl-2 col-4 col-md-3 col-lg-2 col-sm-4 mx-auto item-loading'>

            <div className="card">

            <div className="img-container-loading d-none d-lg-block">
            <div className="card-img-top-loading" style={{width:'100%',height:'14rem'}} />
           </div>

           <div className="img-container-loading d-none d-md-block d-lg-none">
            <div className="card-img-top-loading" style={{width:'100%',height:'13rem'}} />
           </div>

           <div className="img-container-loading d-md-none">
             <div className="card-img-top-loading" style={{width:'100%',height:'12rem'}} /> 
           </div>

           {/* <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 discount-div text-start">
           <div className="discount-perc">
                { (item.discountPercentage) && <span className='discount-value'><span className='minus'>-</span>{`${item.discountPercentage}%`}</span> }
             </div>
            </div> */}

            </div>

            <div className="card-footer">

            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'60%'}/> 
            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'70%'}/> 
            <Skeleton className="mt-2" count={1} baseColor="var(--lightGrey6)"/> 
            <Skeleton className='mt-3' count={1} baseColor="var(--lightGrey6)" width={'80%'}/> 

            </div>    

            </div>

            </React.Fragment>
  )
}