import React,{useState,useEffect} from 'react';
import { Item } from './Item';
import styledComponents from 'styled-components';
import { OfferItem } from './OfferItem';
import axios from 'axios';
import { Loading } from '../loading/Loading';

export const OfferList = () => {

  const [country,setCountry]=useState('');

  const [items,setItems] = useState([]);
  const [loading,setLoading]=useState(true);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

  useEffect(()=>{

    const item_url=process.env.REACT_APP_PRODUCT;

    setLoading(true);

    if(!isOnline)
    return;

    axios.get(`${item_url}/new-offers`,{
        headers:{
        //    'Authorization':'bearer '.concat(token)
          }
    }).then(res=>{
        setItems(res.data);
        setLoading(false);
    }).catch(error=>{
        console.log(error);
        setLoading(false);
    });

  },[isOnline]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth >= 1024);
  };


  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        const country = data.country_name;
        setCountry(country);
      })
      .catch((error) => {
        console.error('Error fetching country:', error);
      });
  }, [country]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const containerClass = isWideScreen ? 'container' : 'container-fluid';
  
     return (
    <OfferListContainer>
         <div className={containerClass}>
            <div className='row'>

            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-xl d-none d-xl-block">
            <div className="row">
            { items && items.slice(0,4).map((prod) => <Item key={prod.productId} item={prod} country={country}/>) }
             </div>
            </div> }


            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-md d-none d-md-block d-xl-none">
            <div className="row">
            { items && items.slice(0,3).map((prod) => <Item key={prod.productId} item={prod} country={country}/>) }
             </div>
            </div> }


            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-sm d-md-none">
            <div className="row mt-2">
            { items && items.slice(0,4).map((prod) => <OfferItem key={prod.productId} item={prod} country={country}/>) }
             </div>
            </div> }

            { (loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 loading-div">
            <Loading/>
            </div> }
                    
            </div>
            </div>
    </OfferListContainer>
  )
}

const OfferListContainer = styledComponents.div`

background-color: #ffffff;

.item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scaleX(0.9) scaleY(0.95);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    border-radius: 10px 10px 0 0;
    color: inherit;
    text-decoration: none;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
   }

.card{
    background: transparent;
    border: none;
  }

  .card-footer{
    padding: 0.5rem 1.5rem 0 1rem;
    background-color: rgb(245, 245, 245);
    padding-bottom: 0.6rem;
    height:15.66rem;
  }

.img-container {
    background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    border: none;
  }

  .card-img-top{
    border-radius: 10px;
    transform: scaleX(0.8) scaleY(1);
  }

  .item-price{

    position: relative;

    .currency{
      font-size: 0.88rem; 
      position: absolute;
      bottom: 0.64rem; 
      font-family: 'Open Sans', sans-serif; 
      // font-family: 'Roboto', sans-serif;
    }

    .price{
      font-size: 1.6rem;
      position: relative;
      padding-left: 0.62rem;
      font-family: 'Karla', sans-serif;
    }

    .cents{
      font-size: 0.9rem; 
      position: absolute;
      bottom: 0.67rem; 
      margin-left:0.04rem;
      // font-family: 'Open Sans', sans-serif; 
      // font-family: 'Roboto', sans-serif;
      font-family: 'Karla', sans-serif;
      color: var(--lightBlack);
      // font-weight:bold;
    }
  }

  @media (min-width: 1400px) {
    .item-price{

      .currency{
        bottom: 0.56rem; 
        font-family: 'Roboto', sans-serif;
      }
      .cents{
        bottom: 0.6rem; 
      }

  }
}

  .prev-price{
    position: relative;
    // font-family: 'Open Sans', sans-serif; 
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    color: var(--lightGrey2);
    white-space: nowrap;
    top:-0.4rem;

    .currency{
      letter-spacing: 0.1rem;
    }

    .price{
      font-weight:bold;
    }

    .cents{
      font-weight:bold;
    }

    .discounted-price{
      display: inline-block;
      white-space: nowrap;
      text-decoration: line-through var(--lightDark2);
      text-decoration-thickness: 0.01rem;
    }

  }


  .saving{
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    color: var(--lightRed);
    font-weight:bold;
    letter-spacing:0.01em;
    margin-left: 1rem;

  }

  @media (max-width: 820px) {
    .saving{
      font-size: 0.75rem;
      margin-left: 0.6rem;
    }
  }

  .product-name-div{
    position: relative;
    margin-top: 0.8rem;
    top:-0.8rem;
  }

  .product-name{
    color: var(--pureBlack);
    // font-family: 'Roboto', sans-serif;
    font-family: 'Radio Canada', sans-serif;
    font-size: 1rem;
    letter-spacing:0.01em;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; 
    max-height: 2.8em;
  }

  .brand-div{
    // padding-top:0.2rem;
    position: relative;
    padding-bottom:0.25rem;
    top:-0.9rem;
  }

  .shipping-div{
    position: relative;
    margin-top: -0.55rem;
  }

  .shipping-icon{
       color: var(--lightGreen);
       font-size: 0.9rem;
  }

  .available{
    // font-family: 'Roboto', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-size: 0.9rem;
    letter-spacing:0.01em;
    color: var(--pureBlack);
    margin-left:0.3rem;

    .country{
      font-family: 'Radio Canada', sans-serif;
      font-weight:bold;
    }
   
  }

  .discount-perc{
    position: absolute;
    top:1.5%;
    bottom:4.5%;
    z-index:1111;
    padding-left: 0.4rem;
    

    .discount-value{
      background: var(--brightRed);
      // background: var(--lightPink);
      border-radius:0.2rem;
      padding: 0.3rem 0.3rem;
      font-family: 'Ubuntu', sans-serif;
      color: #ffffff;
      font-size: 0.9rem;
    }

    .minus{
      letter-spacing:0.1em;
    }
  }

  .brand-img{
    border-radius: 4px;
    transform: scaleX(1) scaleY(0.8);
  }

  .item-list-sm{
    padding:0;
    margin:0;
  }

  .offer-duration{
    position: relative;
    top:-0.8rem;
  }

  .offer-time{
    position: relative;
    top:-0.8rem;
  }

  .material{
    margin-left: 1.5rem;
    // font-family: 'Karla', sans-serif;
    // font-family: 'Ubuntu', sans-serif;
    font-family: 'Radio Canada', sans-serif;
    font-weight:bold;
    // background: linear-gradient(90deg, #808080, #808080, #909090);
    // background: linear-gradient(90deg, #606060, #808080);
    // color: var(--lightBlack);
    color: var(--lightDark2);
    background-color: #d3d3d3;
    padding:0.15rem 0.5rem;
    border-radius:0.2rem;
    font-size:0.9rem;
    letter-spacing:0.01em;
  }

  .loading-div{
    padding: 0;
    margin: 0;
  }

`;