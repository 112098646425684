import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const Item = () => {

  return (
     <React.Fragment>
       
            <div className='col-xl-3 col-12 col-md-4 col-lg-4 col-sm-12 mx-auto item-loading'>

            <div className="card">

            <div className="img-container-loading d-none d-lg-block">
            <div className="card-img-top-loading" style={{width:'100%',height:'16rem'}} /> 
           </div>

           <div className="img-container-loading d-lg-none">
             <div className="card-img-top-loading" style={{width:'100%',height:'14rem'}}/>
           </div>


            </div>

            <div className="card-footer-loading">
            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'60%'}/> 
            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'80%'}/> 
            <Skeleton count={1} baseColor="var(--lightGrey6)"/> 
            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'70%'}/> 
            <Skeleton count={1} baseColor="var(--lightGrey6)" width={'80%'}/> 
            </div>

            </div>

            </React.Fragment>
  )
}
