import styledComponents from "styled-components";

export const SearchButtonSm=styledComponents.button`

border:none;
border-radius:0.6rem;
padding: 0.8rem 5rem;
font-size:1.1rem;
outline:none;
background: var(--mainBlue);
color: #ffffff;
border: none;
cursor:pointer;
transition: 1s all;
font-family: 'Karla', sans-serif;
transition: background 0.3s ease;

&:hover{
    background: var(--mainBlue2);
    //color: var(--lightOrange);
    transition: background 0.3s ease;
}


`;