import React,{useState,useRef,useEffect} from 'react'
import styledComponents from 'styled-components'
import { BrandTitle } from './BrandTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ForOption } from './ForOption';
import { faVenus } from '@fortawesome/free-solid-svg-icons';
import { faMars } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { CtgOption } from './CtgOption';
import { PriceOption } from './PriceOption';
import { SearchButton } from './SearchButton';
import { Link } from 'react-router-dom';

export const SearchBar = ({selectedForOption,setSelectedForOption,selectedCtgOption,setSelectedCtgOption,selectedPriceOption,setSelectedPriceOption}) => {

  const forDivRef = useRef(null);
  const ctgDivRef = useRef(null);
  const priceDivRef = useRef(null);

  const [clickedFor,setClickedFor]=useState(false);
  const [clickedCtg,setClickedCtg]=useState(false);
  const [clickedPrice,setClickedPrice]=useState(false);


  const clickedForHandler=(flag)=>{
    setClickedFor(flag);
  }

  const clickedCtgHandler=(flag)=>{
    setClickedCtg(flag);
  }

  const clickedPriceHandler=(flag)=>{
    setClickedPrice(flag);
  }

  const handleOutsideClick = (event) => {
    if (forDivRef.current && !forDivRef.current.contains(event.target)) {
        setClickedFor(false);
    }
    if (ctgDivRef.current && !ctgDivRef.current.contains(event.target)) {
       setClickedCtg(false);
    }
    if (priceDivRef.current && !priceDivRef.current.contains(event.target)) {
       setClickedPrice(false);
    }
  };


  useEffect(() => {
    
    // Attach event listener when the component mounts
    window.addEventListener('click', handleOutsideClick);

    // Detach event listener when the component unmounts
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const forValue=(f)=>{
    switch(f.toLowerCase()){
      case "her":
        return "for-her";
      case "him":
        return "for-him";
      case "couple":
        return "for-couple";
    }
  }

  const priceValue=(p)=>{
    switch(p.toLowerCase()){
      case "under $25":
        return "u1";
      case "$25 - $50":
        return "u2";
      case "$50 - $100":
          return "u3";  
      case "$100 - $200":
            return "u4";  
      case "$200 & above":
            return "u5";  
      case "any price":
            return "u6";  
      default:
        return "u6"; 
    }
  }

  const searchToys=(forOption,ctgOption,priceOption)=>{
    
       if(forOption && ctgOption && priceOption){
         return `/s/${encodeURIComponent(forValue(forOption))}/${encodeURIComponent(ctgOption).toLowerCase().replaceAll("%20","-")}?price=${priceValue(priceOption)}`;
       }
       return;
  }


  return (

    <SearchBarContainer>
    
    <div className='container'>
    <div className='row'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title mb-3'>
        <BrandTitle/>
    </div>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto search-bar d-flex'>
           
    <div ref={forDivRef} className='col-xl-3 col-3 col-md-3 col-lg-3 col-sm-3 mx-auto for' onClick={()=>clickedForHandler(!clickedFor)}>
        <div className='search-for text-capitalize'>
              <label className='for-name text-capitalize' style={{ marginBottom: '2px' }}>for</label>
              {/* <input class="form-control text-start" type="text" placeholder='Her,Him,Couple' name="for"/> */}
              <div className={(selectedForOption) ? 'for-selected' : 'for-select'}>{(selectedForOption) ? `${selectedForOption}` : `Her, Him or Couple`} { (selectedForOption==='her') && <FontAwesomeIcon icon={faVenus} className="selected-icon ps-1" />} { (selectedForOption==='him') && <FontAwesomeIcon icon={faMars} className="selected-icon ps-1" />} { (selectedForOption==='couple') && <FontAwesomeIcon icon={faHeart} className="selected-icon ps-1" />} <FontAwesomeIcon icon={faAngleDown} className="angle-down" /> </div>
        </div>

      <div className="for-option-div col-12">
        { (clickedFor) && <ForOption selectedForOption={selectedForOption} selectedForOptionHandler={setSelectedForOption}/> }
        </div>

    </div>

    <div ref={ctgDivRef} className='col-xl-4 col-3 col-md-3 col-lg-4 col-sm-3 mx-auto accessories px-1' onClick={()=>clickedCtgHandler(!clickedCtg)}>

    <div className='search-category text-capitalize'>
          <label className='ctg-name text-capitalize' style={{ marginBottom: '2px' }}>categories</label>
          <div className={(selectedCtgOption) ? 'for-selected' : 'for-select'}>{(selectedCtgOption) ? `${selectedCtgOption}` : `Dildos, Vibrators, Sex Dolls, ...`} <FontAwesomeIcon icon={faAngleDown} className="angle-down" /></div>

    </div>

     <div className="ctg-option-div col-12">
     { (clickedCtg) && <CtgOption selectedCtgOption={selectedCtgOption} selectedCtgOptionHandler={setSelectedCtgOption}/> }
      </div>

    </div>

    <div ref={priceDivRef} className='col-xl-3 col-3 col-md-3 col-lg-3 col-sm-3 mx-auto price' onClick={()=>clickedPriceHandler(!clickedPrice)}>

    <div className='search-price text-capitalize'>
          <label className='price-name text-capitalize' style={{ marginBottom: '2px' }}>price</label>
          <div className={(selectedPriceOption) ? 'for-selected' : 'for-select'}>{(selectedPriceOption) ? `${selectedPriceOption}` : `Any Price, Under $25, ...`} <FontAwesomeIcon icon={faAngleDown} className="angle-down" /></div>
    </div>

    <div className="price-option-div col-12">
        { (clickedPrice) && <PriceOption selectedPriceOption={selectedPriceOption} selectedPriceOptionHandler={setSelectedPriceOption}/> }
        </div>

    </div>

    <div className='col-xl-2 col-3 col-md-2 col-lg-2 col-sm-3 mx-auto search'>
       <Link to={searchToys(selectedForOption,selectedCtgOption,selectedPriceOption)}><SearchButton className='search-button'>Search</SearchButton></Link>
    </div>

    </div>

        </div>
    </div>

    </SearchBarContainer>
  )
}

const SearchBarContainer= styledComponents.div`

.search-bar{
  padding-left: 1.4rem;
  padding-bottom:1.5rem;
}

.for{
  position: relative;
  cursor: pointer;
}

.accessories{
  position: relative;
  cursor: pointer;
}

.price{
  position: relative;
  cursor: pointer;
}

.for-option-div{
  position: absolute;
  z-index: 1111;
}

.ctg-option-div{
  position: absolute;
  z-index: 1111;
}

.price-option-div{
  position: absolute;
  z-index: 1111;
}

.search-for{
    background-color: #ffffff;
    padding: 0.6rem 0;
    border-radius: 0.6rem 0 0 0.6rem;
    cursor: pointer;
}

.search-category{
  background-color: #ffffff;
  padding: 0.6rem 0;
  // border-radius: 0.8rem 0 0 0.8rem;
  cursor: pointer;
}

.search-price{
  background-color: #ffffff;
  padding: 0.6rem 0;
  border-radius: 0 0.6rem 0.6rem 0;
  cursor: pointer;
}

.for-option{
  cursor: pointer;
}

.for-name{
    margin-left:1.1rem;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    // font-family: 'Merriweather', serif;
    font-size: 0.9rem;
    cursor: pointer;
}

.ctg-name{
  margin-left:1.1rem;
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  // font-family: 'Merriweather', serif;
  font-size: 0.9rem;
  cursor: pointer;
}

.price-name{
  margin-left:1.1rem;
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  // font-family: 'Merriweather', serif;
  font-size: 0.9rem;
  cursor: pointer;
}

// .search-for .form-select{
//     background-color: #ffffff;
//     border:none;
//     box-shadow: none;
//     text-align:left;
//     position: relative;
//     padding-right:1rem;
//     color: var(--lightGrey) !important;
//     font-family: 'Karla', sans-serif;
// }

.for-select {
    color: var(--lightGrey2);
    font-family: 'Karla', sans-serif;
    font-size:1rem;
    opacity:0.9;
    margin-left:1.1rem;
    align-items: center;
    position: relative;
  }

  .for-selected{
    color: var(--mainDark2);
    font-family: 'Karla', sans-serif;
    font-size:1rem;
    margin-left:1.1rem;
    align-items: center;
    position: relative;
  }

  .custom-dropdown-options{
    background-color: #ffffff;
    list-style-type: none;
    padding: 0.6rem 0;
    margin: 0;
    border-radius: 0.5rem;
    cursor: auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  }

  .price-dropdown-options{
    background-color: #ffffff;
    list-style-type: none;
    padding: 0.6rem 0;
    margin: 0;
    border-radius: 0.5rem;
    cursor: auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  }

  .ctg-dropdown-options{
    background-color: #ffffff;
    list-style-type: none;
    padding: 0.6rem 0;
    margin: 0;
    border-radius: 0.5rem;
    max-height: 400px;
    overflow-y: auto;
    cursor: auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);

    ::-webkit-scrollbar{
      width:0.8rem;
      background-color: transparent;;
    }

    ::-webkit-scrollbar-thumb{
      /* border-radius:0.5rem;
    background: linear-gradient(transparent,#30ff00);  
    background: var(--lightGrey4);
    min-height: 20px; */
    
    background-color: var(--lightPink2);
    border: 2px solid transparent;
    border-radius: 5px;
    background-clip: content-box;
    }
    
  }

  .ctg-value-dropdown-options{
    list-style-type: none;
  }

  .custom-dropdown-options li{
    padding: 0.8rem 0;
  }

  .price-dropdown-options li{
    padding: 0.4rem 0;
  }

  .ctg-dropdown-option{
    padding: 0.4rem 0;
    cursor: pointer;
  }

  .selected-ctg-dropdown-option{
    padding: 0.4rem 0;
    cursor: pointer;
  }

  .ctg-value-dropdown-option{
     padding: 0.1rem 0;
  }

  .selected-ctg-option{
    padding: 0.1rem 0;
  }

  .ctg-dropdown-option .ctg{
    margin-left: 1.1rem;
    font-family: 'Open Sans', sans-serif; 
    font-weight: bold;
    font-size: 0.9rem;
  }

  .selected-ctg-dropdown-option .ctg{
    margin-left: 1.1rem;
    font-family: 'Open Sans', sans-serif; 
    font-weight: bold;
    font-size: 0.9rem;
  }

  .custom-dropdown-option{
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .selected-for-option{
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #f8d7dc;
    
  }

  .price-dropdown-option{
    cursor:pointer;
    transition: background-color 0.3s ease;
  }

  .single-ctg-dropdown-option{
    transition: background-color 0.3s ease;
  }


  .custom-dropdown-option .option-value{
    margin-left: 1.1rem;

    .value{
      font-family: 'Open Sans', sans-serif; 
      font-size:0.9rem;
      color: var(--mainDark2);
    }

    .for-icon{
      font-size:1rem;
      color: var(--lightGrey2);
    }
  }

 .selected-for-option .option-value{
  margin-left: 1.1rem;

  .value{
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
    color: var(--mainDark2);
  }

  .for-icon{
    font-size:1rem;
    color: var(--lightGrey2);
  }
}

  .price-dropdown-option .option-value{

    margin-left: 1.1rem;

    .value{
     // font-family: 'Open Sans', sans-serif; 
     font-family: 'Ubuntu', sans-serif;
      font-size:0.9rem;
      color: var(--lightGrey2);
      cursor:pointer;
    }

  }

  .selected-price-option .option-value{

    margin-left: 1.1rem;

    .value{
     // font-family: 'Open Sans', sans-serif; 
     font-family: 'Ubuntu', sans-serif;
      font-size:0.9rem;
      color: var(--lightGrey2);
      cursor:pointer;
    }

  }

  .ctg-value-dropdown-option .ctg-value{
     margin-left: 0.5rem;
    // font-family: 'Open Sans', sans-serif; 
    font-size: 0.95rem;
    color: var(--lightGrey2);
    font-family: 'Karla', sans-serif;
    transition: color 0.3s ease;
  }

  .selected-ctg-option .ctg-value{
    margin-left: 0.5rem;
    // font-family: 'Open Sans', sans-serif; 
    font-size: 0.95rem;
    color: var(--lightGrey2);
    font-family: 'Karla', sans-serif;
    transition: color 0.3s ease;
  }

  .ctg-value-dropdown-option:hover{
    .ctg-value{
    color: var(--mainDark2);
    }
  }

  .selected-ctg-option{
    .ctg-value{
      color: var(--mainDark2);
      }
  }

  .selected-icon{
    font-size:0.9rem;
    color: var(--lightGrey2);
  }


  .custom-dropdown-option:hover {
    background-color: var(--lightGrey5);
  }

  .selected-for-option:hover {
    background-color: var(--lightGrey5);
  }

  .price-dropdown-option:hover {
    background-color: var(--lightGrey5);
  }

  .selected-price-option {
    transition: background-color 0.3s ease;
    background-color: #f8d7dc;
  }

  .selected-price-option:hover {
    background-color: var(--lightGrey5);
  }

  .single-ctg-dropdown-option:hover{
    background-color: var(--lightGrey5);
  }


  .single-selected-ctg-dropdown-option{
    transition: background-color 0.3s ease;
    background-color: #f8d7dc;
  }

  .single-selected-ctg-dropdown-option:hover{
    background-color: var(--lightGrey5);
  }

  .angle-down {
    position: absolute;
    margin-right: 1.3rem;
    right:0;
    top:0.25rem;
  }

  @media (max-width: 600px) {
    .angle-down {
      margin-right: 1.2rem; 
    }
}


  .search-button{
    margin-left:1rem;
  }


`;