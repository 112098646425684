import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export const OfferOption = ({optionValue}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

    const options = [
        { value: 'featured', label: 'Featured' },
        { value: 'low', label: 'Price, Low to High' },
        { value: 'high', label: 'Price, High to Low' },
        { value: 'new', label: 'Date, New to Old' },
        { value: 'old', label: 'Date, Old to New' },
        { value: 'selling', label: 'Best Selling' }
      ];

      const handleOptionClick = (option) => {
        
        const pathName=location.pathname;
        const sort = queryParams.get('filter');

        const p = queryParams.get('page');
        const pageNum = p ? parseInt(p) : 1;

        if(option.label==='Featured')
        return navigate(`${pathName}`);
        else
        if(pageNum==1 || sort !== getSortedValue(option.label))
        return navigate(`${pathName}?filter=${getSortedValue(option.label)}`);
        else
        return navigate(`${pathName}?filter=${getSortedValue(option.label)}&page=${pageNum}`);
        
      };

      const getSortedValue=(s)=>{
        switch(s){
          case "Featured":
            return "s1";
          case "Price, Low to High":
            return "s2";
          case "Price, High to Low":
              return "s3";  
          case "Date, New to Old":
                return "s4";  
          case "Date, Old to New":
                return "s5";  
          case "Best Selling":
                return "s6";              
          default:
            return "s1"; 
       }
      }

  return (
    <div className='container py-1'>
    <div className='row'>

    <ul className="offer-dropdown-options">
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => handleOptionClick(option)}
            className={(optionValue.toLowerCase()===option.label.toLowerCase())? `selected-option`: `offer-dropdown-option` }
          >
           { <span className='option-value'><span className='value'>{option.label}</span></span> }
          </li>
        ))}
      </ul>

        </div>
        </div>
  )
}
