import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { NewOption } from '../filter/NewOption';
import { Filter } from '../offer-page/Filter';
import { FilterSm } from '../offer-page/FilterSm';
import { useLocation, useParams } from 'react-router';
import { BrandList } from './BrandList';
import { Helmet } from 'react-helmet';

export const BrandPage = () => {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { brand } = useParams();

   const transformString = (inputString) => {
    const words = inputString.split(/[-\s]/);
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const transformedString = capitalizedWords.join(' ');

    return transformedString;
  };

  const [pageTitle, setPageTitle] = useState(`${transformString(brand)} Sex Toys - Joyscanner`);
  const [pageDescription, setPageDescription] = useState(`Discover the ${transformString(brand)} Sex Toys. Find the top 100 most popular ${transformString(brand)} Sex Toys in Joyscanner.`);

  useEffect(()=>{
     const title = `${transformString(brand)} Sex Toys - Joyscanner`;
     document.title = title;
  },[]);

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 720);
    const [optionValue,setOptionValue]=useState(`Featured`);

    const [clickedOption,setClickedOption]=useState(false);

    const [isWideScreen2, setIsWideScreen2] = useState(window.innerWidth >= 1024);


    const result = "Results";
    const category= transformString(brand);

  const handleResize2 = () => {
    setIsWideScreen2(window.innerWidth >= 1024);
  };

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 720);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

      useEffect(() => {
        window.addEventListener('resize', handleResize2);
    
        return () => {
          window.removeEventListener('resize', handleResize2);
        };
      }, []); 

      useEffect(()=>{
        const sort = queryParams.get('filter');
        setOptionValue(getOptionValue(sort));
      },[queryParams]);

      const getOptionValue=(s)=>{
        switch(s){
          case "s1":
            return "Featured";
          case "s2":
            return "Price, Low to High";
          case "s3":
              return "Price, High to Low";  
          case "s4":
                return "Date, New to Old";  
          case "s5":
                return "Date, Old to New";  
          case "s6":
                return "Best Selling";        
          default:
            return "Featured"; 
       }
      }
    
      const containerClass = isWideScreen2 ? 'container' : 'container-fluid';
    

  return (
      <ReleaseContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>

    <div className={containerClass}>
    <div className='row'>
           
           <React.Fragment className="filter-md">
          { (isWideScreen) && <Filter selectedValue={optionValue} clickedOption={clickedOption} setClickedOption={setClickedOption} result={result} category={category}/> }
          </React.Fragment>

          <React.Fragment className="filter-sm">
          { (!isWideScreen) && <FilterSm selectedValue={optionValue} clickedOption={clickedOption} setClickedOption={setClickedOption} result={result} category={category}/> }
          </React.Fragment>

          <div className='offer-option-div col-xl-2 col-lg-3 col-md-3 col-6'>
               { (clickedOption) && <NewOption optionValue={optionValue}/> }
          </div>
  
    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto brand-page-list-div'>
       <BrandList/>
    </div>

    </div>
    </div>
    </ReleaseContainer>
  )
}

const ReleaseContainer = styledComponents.div`

background-color: #ffffff;

.title-page-div{
  padding:1.5rem 0 0 0;
}

.offer-option-div{
  position: absolute;
  z-index: 1118;
  right:7.65rem;
  top:20.4rem;
}


@media (max-width: 1280px) {
  .offer-option-div{
    right:5.3rem;
    top:20.4rem;
  }
}

@media (max-width: 1024px) {
  .offer-option-div{
    right:3.15rem;
    top:20.4rem;
  }
}

@media (max-width: 932px) {
  .offer-option-div{
    right:1.8rem;
    top:31.5rem;
  }
}

@media (max-width: 820px) {
  .offer-option-div{
    right:0.9rem;
    top:31.5rem;
  }
}

@media (max-width: 540px) {
  .offer-option-div{
    right:0.1rem;
    top:33.5rem;
  }
}

@media (max-width: 538px) {
  .offer-option-div{
    right:0.1rem;
    top:34.8rem;
  }
}

@media (max-width: 388px) {
  .offer-option-div{
    right:0.1rem;
    top:34.5rem;
  }
}

.results-div{
  // border: 1px solid green;
}

.sort-div{
padding-right:1.8rem;
}

@media (max-width: 600px) {
  .sort-div{
    margin-top:0.8rem;
    padding-right: 0.8rem;
    // border: 1px solid red;
  }
}

.brand-page-list-div{
  // margin-top:0.2rem;
}

@media (max-width: 600px) {
  .brand-page-list-div{
    margin-top:0.6rem;
  }
}



.sort-label{
  position:relative;
  top: -0.2rem;
  // background: linear-gradient(to left, #f0f0f0, #ffffff);
  background-color: #f5f5f5;
  padding:0.5rem;
  border-radius: 0.4rem;
  font-family: 'Karla', sans-serif;
  font-size:0.9rem;
  cursor:pointer;
  transition: background-color 0.3s ease;

  &:hover{
    background-color: #f0f0f0;
  }
}

.sort-label-sm{
  // position:relative;
  // top: -0.2rem;
  // background: linear-gradient(to left, #f0f0f0, #ffffff);
  background-color: #f5f5f5;
  padding:0.4rem;
  border-radius: 0.4rem;
  font-family: 'Karla', sans-serif;
  font-size:0.8rem;
  cursor:pointer;
  transition: background-color 0.3s ease;

  &:hover{
    background-color: #f0f0f0;
  }
}

.offer-dropdown-options{
  background-color: #ffffff;
  list-style-type: none;
  padding: 0.2rem 0;
  margin: 0;
  border-radius: 0.4rem;
  cursor: auto;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  // box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.offer-dropdown-options li{
  padding: 0.1rem 0;
}

.offer-dropdown-option{
  cursor:pointer;
  transition: background-color 0.3s ease;
}

.selected-option{
  cursor:pointer;
  transition: background-color 0.3s ease;
  // background-color: #b7e1cd;
  // background-color: #d5f3e3;
  background-color: #f8d7dc;
}

.offer-dropdown-option .option-value{

  margin-left: 1rem;

  .value{
   // font-family: 'Open Sans', sans-serif; 
  //  font-family: 'Ubuntu', sans-serif;
  font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    color: var(--lightBlack);
  }

}

.selected-option .option-value{

  margin-left: 1rem;

  .value{
   // font-family: 'Open Sans', sans-serif; 
  //  font-family: 'Ubuntu', sans-serif;
  font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    color: var(--lightBlack);
  }

}

.offer-dropdown-option:hover {
  background-color: var(--lightGrey5);
}

.selected-option:hover{
  background-color: var(--lightGrey5);
}

.angle-down{
  position:relative;
  top: 0.1rem;
}

`;