import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { LoadingSm } from '../loading/LoadingSm';
import {Item} from './Item';
import axios from 'axios';

export const ToyList = () => {

  const [items,setItems] = useState([]);
  const [loading,setLoading]=useState(true);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

  useEffect(()=>{

    const item_url=process.env.REACT_APP_PRODUCT;

    setLoading(true);

    if(!isOnline)
    return;

    axios.get(`${item_url}/under-25`,{
        headers:{
        //    'Authorization':'bearer '.concat(token)
          }
    }).then(res=>{
        setItems(res.data);
        setLoading(false);
    }).catch(error=>{
        console.log(error);
        setLoading(false);
    });

  },[isOnline]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth >= 1024);
  };


  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const containerClass = isWideScreen ? 'container' : 'container-fluid';
  
     return (
    <ToyListContainer>
         <div className={containerClass}>
            <div className='row'>

            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list">
            <div className="row overflow-auto flex-nowrap">
            { items && items.map((prod) => <Item key={prod.productId} item={prod}/>) }
             </div>
            </div> }

            { (loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 loading-div">
            <LoadingSm/>
            </div> }
                    
            </div>
            </div>
    </ToyListContainer>
  )
}

const ToyListContainer = styledComponents.div`

background-color: #ffffff;

.row::-webkit-scrollbar {
  display: none;
}


.item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scaleX(0.95) scaleY(0.95);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    border-radius: 5px 5px 0 0;
    color: inherit;
    text-decoration: none;

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
   }

   @media (max-width: 1368px) {
    .item{
      width: auto;
    }
  }

.card{
    background: transparent;
    border: none;
  }

  .card-footer{
    padding: 0.4rem 1.2rem 0 0.8rem;
    background-color: rgb(245, 245, 245);
    //  margin-bottom: -1.6rem;
    height: 8.8rem;

  }

  @media (max-width: 1024px) {
    .card-footer{
      padding: 0.4rem 1.4rem 0 0.8rem;
    }
  }

  @media (max-width: 600px) {
    .card-footer{
      height: 7.4rem;
    }
  }

.img-container {
    background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
    // padding: 20px 30px;
    border-radius: 5px 5px 0 0;
    border: none;
  }

  .card-img-top{
    border-radius: 5px;
    transform: scaleX(0.85) scaleY(0.9);
  }

  .price-div-sm{
    position: relative;
    top:-1rem;
  }

  .item-price{

    position: relative;

    .currency{
      font-size: 0.8rem; 
      position: absolute;
      bottom: 0.54rem; 
      font-family: 'Open Sans', sans-serif; 
      // font-family: 'Roboto', sans-serif;
    }

    .price{
      font-size: 1.45rem;
      position: relative;
      padding-left: 0.55rem;
      font-family: 'Karla', sans-serif;
    }

    .cents{
      font-size: 0.85rem; 
      position: absolute;
      bottom: 0.56rem; 
      margin-left:0.04rem;
      // font-family: 'Open Sans', sans-serif; 
      // font-family: 'Roboto', sans-serif;
      font-family: 'Karla', sans-serif;
      color: var(--lightBlack);
      // font-weight:bold;
    }
  }

  @media (min-width: 1200px) {
    .item-price{
      .currency{
        font-size: 0.85rem; 
        bottom: 0.54rem; 
      }
    }
 }

  .prev-price-div-sm{
    position: relative;
    top:-1.1rem;
  }

  .prev-price{
    position: relative;
    // font-family: 'Open Sans', sans-serif; 
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    color: var(--lightGrey2);
    white-space: nowrap;
    top:-0.4rem;

    .currency{
      letter-spacing: 0.1rem;
      // font-family: 'Open Sans', sans-serif; 
    }

    .price{
      font-weight:bold;
      // font-family: 'Roboto', sans-serif;
    }

    .cents{
      font-weight:bold;
      // font-family: 'Roboto', sans-serif;
    }

    .discounted-price{
      display: inline-block;
      white-space: nowrap;
      text-decoration: line-through var(--lightDark2);
      text-decoration-thickness: 0.01rem;
    }

  }

  .saving{
    font-family: 'Roboto', sans-serif;
    font-size: 0.75rem;
    color: var(--lightRed);
    font-weight:bold;
    letter-spacing:0.01em;

  }

  .product-name-div{
    position: relative;
    margin-top: 0.8rem;
    top:-0.8rem;
  }

  .product-name-div-sm{
    position: relative;
    margin-top: 0.8rem;
    top:-0.8rem;
  }

  .product-name{
    color: var(--pureBlack);
    // font-family: 'Roboto', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-size: 0.9rem;
    // letter-spacing:0.01em;

    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; 
  max-height: 4em;
  }

  .product-name-sm{
    color: var(--pureBlack);
    // font-family: 'Roboto', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-size: 0.9rem;
    // letter-spacing:0.01em;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; 
    max-height: 4em;
  }

  .brand-div{
    // padding-top:0.2rem;
    position: relative;
    padding-bottom:0.25rem;
    top:-1.15rem;
  }

  .brand-div-sm{
    position: relative;
    padding-bottom:0.25rem;
    top:-1.6rem;
  }

  .available{
    // font-family: 'Almarai', sans-serif;
    // font-family: 'Sarabun', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    letter-spacing:0.01em;
    color: var(--pureBlack);
    margin-left:0.3rem;

    .country{
      font-family: 'Radio Canada', sans-serif;
      font-weight:bold;
    }
   
  }

  .discount-perc{
    position: absolute;
    top:1.5%;
    bottom:4.5%;
    z-index:1111;
    padding-left: 0.4rem;
    

    .discount-value{
      background: var(--brightRed);
      // background: var(--lightPink);
      border-radius:0.2rem;
      padding: 0.3rem 0.3rem;
      font-family: 'Ubuntu', sans-serif;
      color: #ffffff;
      font-size: 0.9rem;
    }

    .minus{
      letter-spacing:0.1em;
    }
  }

  .brand-img{
    border-radius: 4px;
    transform: scaleX(1) scaleY(0.8);
  }

  .material{
    margin-left: 0.8rem;
    // font-family: 'Karla', sans-serif;
    // font-family: 'Open Sans', sans-serif; 
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Roboto', sans-serif;
    // font-weight:bold;
    // background: linear-gradient(90deg, #808080, #808080, #909090);
    // background: linear-gradient(90deg, #606060, #808080);
    color: var(--lightBlack);
    background-color: #d3d3d3;
    padding:0.15rem 0.5rem;
    border-radius:0.2rem;
    font-size:0.83rem;
    letter-spacing:0.02em;
   
  }

  @media (max-width: 1024px) {
    .material{
      font-family: 'Roboto', sans-serif;
    }
  }

  .loading-div{
    padding: 0;
    margin: 0;
  }

`;