import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';


export const Item = ({item,country}) => {

  const [truncatedText, setTruncatedText] = useState(item.productName);

  const wholePrice = (item.price) && item.price.toString().split('.')[0];
  const cents = (item.price) && item.price.toString().split('.')[1];

  const prevPrice = (item.prevPrice) && item.prevPrice.toString().split('.')[0];
  const prevCents = (item.prevPrice) && item.prevPrice.toString().split('.')[1];


  useEffect(() => {
    const trimmedText = item.productName.substring(0, 70);

    var finalText = ``;

    if(item.productName.length>70)
        finalText = trimmedText + '...';
        else
        finalText = trimmedText;

    setTruncatedText(finalText);
  }, []);


  return (
     <React.Fragment>
       
            <a href={`/product_deeplink?id=${item.productId}&dest=${item.platform}&prod_link=${item.itemUrl}`} target="_blank" className='col-xl-3 col-12 col-md-4 col-lg-4 col-sm-12 mx-auto item'>

            <div className="card">

            <div className="img-container d-none d-lg-block">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top" style={{width:'100%',height:'16rem'}} loading="lazy"/> }
           </div>

           <div className="img-container d-lg-none">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top" style={{width:'100%',height:'14rem'}} loading="lazy"/> }
           </div>

           <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 discount-div text-start">
           <div className="discount-perc">
                { (item.discountPercentage) && <span className='discount-value'><span className='minus'>-</span>{`${item.discountPercentage}%`}</span> }
             </div>
            </div>

            </div>

            <div className="card-footer">

              <div className='price-div'>
                { (item.price) && <h4 className='item-price text-start'><span className='currency'>$</span><span className='price'>{wholePrice}</span><span className='cents'>{cents}</span></h4> }
              </div>
               <div className='prev-price-div'>
                { (prevPrice && item.discount) && <h4 className='prev-price text-start'> <del className='discounted-price'> <span className='currency'>$</span><span className='price'>{prevPrice}</span><span className='cents'>{`.${prevCents}`}</span> </del> 
                  <span className='saving'>{`Saving $${item.discount} `}<span className='off'>{`(${item.discountPercentage}% OFF)`}</span></span> 
                </h4> }
                </div>

                <div className='product-name-div text-start'>
                { (truncatedText) && <h3 className='product-name' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
                {/* { (truncatedText && truncatedText.length<=50) && <h3 className='product-name d-lg-none' style={{lineHeight: '1.4'}}>{truncatedText}</h3> } */}
                {/* { (truncatedText && truncatedText.length>50) && <h3 className='product-name d-lg-none' style={{lineHeight: '1.4'}}>{`${truncatedText.substring(0,50)}...`}</h3> } */}
                </div>

                <div className='brand-div text-start'>
                { (item.platformImg) && <img src={item.platformImg} alt={`${item.brandName} - Toyscanner`} title={`${item.brandName} - Toyscanner`} className="brand-img" style={{width:'5rem',height:'1.8rem'}} loading="lazy"/>  }
                { (item.material) && <span className='material text-capitalize'>{item.material}</span> }
                </div>

          <div className='shipping-div text-start'>
            { ((country && item.shippingCountry.toLowerCase()==='global') || ( country && item.shippingCountry.toLowerCase()===country.toLowerCase())) && <span className='shipping'> <FontAwesomeIcon icon={faTruckFast} className="shipping-icon" /> <span className='available'>Deliver to <span className='country'>{country}</span></span></span> }
           </div>

            </div>    

            </a>

            </React.Fragment>
  )
}
