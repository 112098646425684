import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router';
import styledComponents from 'styled-components';
import { HomeButton } from './HomeButton';

export const NoProduct = () => {

    const navigate=useNavigate();

    const redirectToHome=()=>{
       return navigate('/');
    }
    
  return (
    <ErrorContainer>

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 not-found text-center">
                          <p className="msg">
                              <span className="h-msg">No Products found. Please adjust your filters and try again.</span>
                                 {/* <br/> */}
                            {/* <span className="s-msg">Either something went wrong or the page doesn't exist anymore.</span> */}
                          </p>

                   </div>{/*not-found*/}

               
       </ErrorContainer>
  )
}

const ErrorContainer = styledComponents.div`

background: #ffffff;

.not-found{

    margin: 3rem 0 3rem 0;
    .h-msg{
        font-size:1.2rem;
        color: var(--mainDark2);
        font-family: 'Open Sans', sans-serif;
    }

    .s-msg{
        font-size:0.9rem;
        color: var(--mainDark2);
        font-family: 'Open Sans', sans-serif;
    }
}

`;