import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { DealList } from './DealList';
import { Title } from './Title';

export const DealListPage = ({d}) => {

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';

  return (
      <DealContainer>
    <div className={containerClass}>
    <div className='row'>
           
    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title-div'>
      { (d.dealName) && <Title title={d.dealName} deal={d.dealType}/> }
    </div>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto deal-list-div'>
        <DealList items={d.products}/>
    </div>

    </div>
    </div>
    </DealContainer>
  )
}

const DealContainer = styledComponents.div`

.title-div{
  padding:1.5rem 0 0 0;
}

.deal-list-div{
  margin-top:2rem;
}

`;