import React,{useState,useEffect} from 'react'


export const Item = ({item}) => {

  const [truncatedText, setTruncatedText] = useState(item.productName);

  const wholePrice = (item.price) && item.price.toString().split('.')[0];
  const cents = (item.price) && item.price.toString().split('.')[1];

  const prevPrice = (item.prevPrice) && item.prevPrice.toString().split('.')[0];
  const prevCents = (item.prevPrice) && item.prevPrice.toString().split('.')[1];

  const material = (item.material && item.material.length>12) ? item.material.split('/')[0] : item.material;

  useEffect(() => {
    const trimmedText = item.productName.substring(0, 50);

    var finalText = ``;

    if(item.productName.length>50)
        finalText = trimmedText + '...';
        else
        finalText = trimmedText;

    setTruncatedText(finalText);
  }, []);


  return (
     <React.Fragment>
       
            <a href={`/product_deeplink?id=${item.productId}&dest=${item.platform}&prod_link=${item.itemUrl}`} target="_blank" className='col-xl-2 col-4 col-md-3 col-lg-2 col-sm-4 mx-auto item'>

            <div className="card">

            <div className="img-container d-none d-lg-block">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top" style={{width:'100%',height:'14rem', objectFit: 'cover'}} loading="lazy"/> }
           </div>

           <div className="img-container d-none d-md-block d-lg-none">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top" style={{width:'100%',height:'13rem', objectFit: 'cover' }} loading="lazy"/> }
           </div>

           <div className="img-container d-md-none">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top" style={{width:'100%',height:'12rem', objectFit: 'cover'}} loading="lazy"/> }
           </div>

           {/* <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 discount-div text-start">
           <div className="discount-perc">
                { (item.discountPercentage) && <span className='discount-value'><span className='minus'>-</span>{`${item.discountPercentage}%`}</span> }
             </div>
            </div> */}

            </div>

            <div className="card-footer">

            {/* <div className='product-name-div-sm text-start d-md-none' style={{ minWidth: '160px' }}>
                { (truncatedText && truncatedText.length<=22) && <h3 className='product-name-sm' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
                { (truncatedText && truncatedText.length>22) && <h3 className='product-name' style={{lineHeight: '1.4'}}>{`${truncatedText.substring(0,22)}...`}</h3> }
                </div> */}
                <div className='product-name-div-sm text-start d-md-none' style={{ minWidth: '140px' }}>
                { (truncatedText && truncatedText.length<=20) && <h3 className='product-name-sm' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
                { (truncatedText && truncatedText.length>20) && <h3 className='product-name-sm' style={{lineHeight: '1.4'}}>{`${truncatedText.substring(0,20)}...`}</h3> }
                </div>

                <div className='price-div-sm d-md-none'>
                { (item.price) && <h4 className='item-price text-start'><span className='currency'>$</span><span className='price'>{wholePrice}</span><span className='cents'>{cents}</span></h4> }
              </div>

              <div className='prev-price-div-sm d-md-none'>
                { (prevPrice && item.discount) && <h4 className='prev-price text-start'> <del className='discounted-price'> <span className='currency'>$</span><span className='price'>{prevPrice}</span><span className='cents'>{`.${prevCents}`}</span> </del> 
                  <span className='saving ps-3'><span className='off'>{`(${item.discountPercentage}% OFF)`}</span></span> 
                </h4> }
                </div>

                <div className='brand-div-sm d-md-none text-start'>
                { (item.platformImg) && <img src={item.platformImg} alt={`${item.brandName} - Toyscanner`} title={`${item.brandName} - Toyscanner`} className="brand-img" style={{width:'4rem',height:'1.6rem'}} loading="lazy"/>  }
                { (item.material) && <span className='material text-capitalize'>{item.material}</span> }
                </div>

              <div className='price-div d-none d-md-block'>
                { (item.price) && <h4 className='item-price text-start'><span className='currency'>$</span><span className='price'>{wholePrice}</span><span className='cents'>{cents}</span></h4> }
              </div>

               <div className='prev-price-div d-none d-md-block'>
                { (prevPrice && item.discount) && <h4 className='prev-price text-start'> <del className='discounted-price'> <span className='currency'>$</span><span className='price'>{prevPrice}</span><span className='cents'>{`.${prevCents}`}</span> </del> 
                  <span className='saving ps-3'><span className='off'>{`(${item.discountPercentage}% OFF)`}</span></span> 
                </h4> }
                </div>

                <div className='product-name-div text-start d-none d-xl-block' style={{ maxWidth: '190px' }}>
                { (truncatedText) && <h3 className='product-name' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
                {/* { (truncatedText && truncatedText.length>40) && <h3 className='product-name d-none d-xl-block' style={{lineHeight: '1.4'}}>{`${truncatedText.substring(0,40)}...`}</h3> } */}
                </div>

                <div className='product-name-div text-start d-none d-md-block d-xl-none' style={{ maxWidth: '155px' }}>
                { (truncatedText) && <h3 className='product-name' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
                {/* { (truncatedText && truncatedText.length>36) && <h3 className='product-name d-none d-md-block d-xl-none' style={{lineHeight: '1.4'}}>{`${truncatedText.substring(0,36)}...`}</h3> } */}
                </div>

                <div className='brand-div text-start d-none d-md-block'>
                { (item.platformImg) && <img src={item.platformImg} alt={`${item.brandName} - Toyscanner`} title={`${item.brandName} - Toyscanner`} className="brand-img" style={{width:'4rem',height:'1.6rem'}} loading="lazy"/>  }
                { (material) && <span className='material text-capitalize'>{material}</span> }
                </div>


            </div>    

            </a>

            </React.Fragment>
  )
}
