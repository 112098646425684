import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { FooterDiv } from './FooterDiv';

export const Footer = () => {

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';


  return (

  <FooterContainer>
    <div className={containerClass}>
            <div className='row'>

            <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 col-xl-12 footer-lg">
              <FooterDiv/>
            </div>

            </div>
            </div>
            </FooterContainer>
  )
}

const FooterContainer = styledComponents.div`


.footer{
    padding: 2.8rem 0 0.8rem 0;
}

.item .name{
    text-decoration:none;
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
    font-weight:bold;
    transition: all 0.5s ease;
    cursor:pointer;

    &:hover{
        color: #ffffff;
        text-decoration: underline;
    }
}

.item .item-name{
   margin: 0.4rem 0;
}

.social-media{
    .icon{
        font-size:1.5rem;
        color: var(--mainWhite);
        cursor:pointer;
        margin: 1rem 0.8rem 1rem 0.8rem;
        // padding: 0.4rem;
        transition: 0.5s ease-in-out;

        &:hover{
            color: #ffffff;
            opacity:0.8;
        }
    }

    @media (max-width: 600px) {
        .icon{
            margin: 0.6rem;
        }  
    }
}   

@media (max-width: 600px) {
    .info-03{
        margin-top: 0.6rem;
    }
    .social-media{
        margin-top: 0.6rem;
    }  
}

@media (max-width: 600px) {
    .social-media .icon{
        font-size:1.4rem;
        margin: 1rem 0.5rem 1rem 0.5rem;
    }  
}

@media (max-width: 376px) {
    .social-media .icon{
        font-size:1.3rem;
        margin: 1rem 0.45rem 1rem 0.45rem;
    }  
}

.trademark{

    margin: 3rem 0 0.5rem 0;

    .moto{
        font-size:0.9rem;
        font-family: 'Open Sans', sans-serif; 
        color: var(--mainWhite);
    }

    .company{
        position: relative;
        top:-0.5rem;
        font-size:0.9rem;
        font-family: 'Open Sans', sans-serif; 
        color: var(--mainWhite);
    }
}

`;