import React from 'react'
import { Link } from 'react-router-dom'

export const Brand = ({brand}) => {

  const findBrand=(name)=>{
    return `/collections/${name.toLowerCase().replaceAll(" ","-")}`;
}

  return (
    <React.Fragment>
         <div className='col-xl-3 col-12 col-md-4 col-lg-3 col-sm-12 mx-auto brand'>

            <div className="card">

            <Link to={findBrand(brand.brandName)} className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12s mx-auto name-img text-center'>
                     
            {/* <div className='col-xl-6 col-6 col-md-6 col-lg-6 col-sm-6 mx-auto brand-name text-start'>
                <h4 className='name'>{brand.brandName}</h4>
             </div> */}

             {/* <div className='col-xl-6 col-6 col-md-6 col-lg-6 col-sm-6 mx-auto brand-img-div text-end'> */}

             <div className="img-container">
             { (brand.brandImg) ? <img src={brand.brandImg} alt={`${brand.brandName} - Toyscanner`} title={`${brand.brandName} - Toyscanner`} className="brand-img" style={{width:'6rem',height:'2.5rem'}} loading="lazy"/> :
             <div className='brand-name-div'> <span className='brand-name'>{brand.brandName}</span> </div> }
             </div>

             {/* </div> */}

             </Link>

         </div>
         </div>
    </React.Fragment>
  )
}
