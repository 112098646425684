import React,{useState,useEffect} from 'react'
import styledComponents from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckFast } from '@fortawesome/free-solid-svg-icons'

export const BestItem = ({item,country}) => {

  const [truncatedText, setTruncatedText] = useState(item.productName);

  const wholePrice = (item.price) && item.price.toString().split('.')[0];
  const cents = (item.price) && item.price.toString().split('.')[1];

  const prevPrice = (item.prevPrice) && item.prevPrice.toString().split('.')[0];
  const prevCents = (item.prevPrice) && item.prevPrice.toString().split('.')[1];

  useEffect(() => {
    const trimmedText = item.productName.substring(0, 70);

    var finalText = ``;

    if(item.productName.length>70)
        finalText = trimmedText + '...';
        else
        finalText = trimmedText;

    setTruncatedText(finalText);
  }, []);


  return (
     <ItemContainer>

<a href={`/product_deeplink?id=${item.productId}&dest=${item.platform}&prod_link=${item.itemUrl}`} target="_blank" className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto item-sm d-flex'>

<div className='col-xl-5 col-5 col-md-5 col-lg-5 col-sm-5 mx-auto item-img-sm'>

<div className="img-container-sm">
           { (item.productImg) && <img src={item.productImg} alt={`${item.productName} - Toyscanner`} title={`${item.productName} - Toyscanner`} className="card-img-top-sm" style={{width:'100%',height:'12rem'}} loading="lazy"/> }
           </div>

           <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 discount-div-sm text-start">
           <div className="discount-perc-sm">
                { (item.discountPercentage) && <span className='discount-value-sm'><span className='minus-sm'>-</span>{`${item.discountPercentage}%`}</span> }
             </div>
            </div>

</div>

<div className='col-xl-7 col-7 col-md-7 col-lg-7 col-sm-7 mx-auto item-detail-sm'>

     <div className='product-name-div-sm text-start'>
        {  (truncatedText) && <h3 className='product-name-sm' style={{lineHeight: '1.4'}}>{truncatedText}</h3> }
          </div>
 
          <div className='material-div-sm'>
          { (item.material) && <span className='material-sm text-capitalize'>{item.material}</span> }
           </div>

          <div className='price-div-sm'>
                { (item.price && item.platformImg) && <h4 className='item-price-sm text-start'><span className='currency-sm'>$</span><span className='price-sm'>{wholePrice}</span><span className='cents-sm'>{cents}</span>
                 <img src={item.platformImg} alt={`${item.brandName} - Toyscanner`} title={`${item.brandName} - Toyscanner`} className="brand-img-sm mb-1" style={{width:'5rem',height:'1.8rem'}} loading="lazy"/> 
                </h4> }
              </div>

              <div className='prev-price-div-sm'>
                { (prevPrice && item.discount) && <h4 className='prev-price-sm text-start'> <del className='discounted-price-sm'> <span className='currency-sm'>$</span><span className='price-sm'>{prevPrice}</span><span className='cents-sm'>{`.${prevCents}`}</span> </del> 
                  <span className='saving-sm'>{`Saving $${item.discount} `}<span className='off-sm'>{`(${item.discountPercentage}% OFF)`}</span></span> 
                </h4> }
                </div>

                {/* <div className='brand-div-sm text-start'>
                {  <img src={`/media/brands/amazon.png`} alt={` - Toyscanner`} title={` - Toyscanner`} className="brand-img-sm" style={{width:'5rem',height:'1.8rem'}} loading="lazy"/>  }
                </div> */}

<div className='shipping-div-sm text-start'>
            { ((country && item.shippingCountry.toLowerCase()==='global') || ( country && item.shippingCountry.toLowerCase()===country.toLowerCase())) && <span className='shipping-sm'> <FontAwesomeIcon icon={faTruckFast} className="shipping-icon-sm" /> <span className='available-sm'>Deliver to <span className='country-sm'>{country}</span></span></span> }
           </div>

</div>

  </a>

     </ItemContainer>
  )
}

const ItemContainer = styledComponents.div`

background-color: #ffffff;
margin: 0.5rem 0;

.item-sm{
  position:relative;
  padding:0 !important;
  margin:0 !important;
  // transform: scale(0.9);
  cursor:pointer;
  transition:0.5s all;
  text-decoration:none;
  border: 1px solid #ccc;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  // transition: box-shadow 0.3s ease;
  border-radius: 0 5px 5px 0;
  color: inherit;
  text-decoration: none;

  // &:hover {
  //   box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  // }
 }

 .item-detail-sm{
  // background-color: rgb(245, 245, 245);
  background: linear-gradient(to bottom, #FFFFFF, #ECECEC);
 }

 .img-container-sm {
  background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
  padding: 10px 15px;
  // border-radius: 10px 10px 0 0;
  border: none;
}

.card-img-top-sm{
  border-radius: 3px;
  transform: scaleY(1);
  transform: scaleX(1);
}

.product-name-div-sm{
  position: relative;
  padding-top: 0.3rem;
  // padding-right: 1.5rem;
}

.product-name-sm{
  position: relative;
  color: var(--lightDark2);
  // font-family: 'Roboto', sans-serif;
  font-family: 'Radio Canada', sans-serif;
  font-size: 0.9rem;
  letter-spacing:0.01em;
  margin-left:0.8rem;
  padding-top:0.3rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; 
  max-height: 5em;
}

.item-price-sm{

  position: relative;
  margin-left:0.8rem;
  top:-0.1rem;

  .currency-sm{
    font-size: 0.8rem; 
    position: absolute;
    bottom: 0.8rem; 
    font-family: 'Open Sans', sans-serif; 
    // font-family: 'Roboto', sans-serif;
  }

  .price-sm{
    font-size: 1.5rem;
    position: relative;
    padding-left: 0.5rem;
    font-family: 'Karla', sans-serif;
  }

  .cents-sm{
    font-size: 0.8rem; 
    position: absolute;
    bottom: 0.82rem; 
    // font-family: 'Open Sans', sans-serif; 
    font-family: 'Roboto', sans-serif;
    color: var(--pureBlack);
    // font-weight:bold;
  }
}

.prev-price-sm{
  position: relative;
  // font-family: 'Open Sans', sans-serif; 
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  color: var(--lightGrey2);
  white-space: nowrap;
  margin-left:0.8rem;
  top:-0.6rem;

  .currency-sm{
    letter-spacing: 0.1rem;
    // font-family: 'Open Sans', sans-serif; 
  }

  .price-sm{
    font-weight:bold;
  }

  .cents-sm{
    font-weight:bold;
  }

  .discounted-price-sm{
    display: inline-block;
    white-space: nowrap;
    text-decoration: line-through var(--lightDark2);
    text-decoration-thickness: 0.01rem;
  }

}


.saving-sm{
  font-family: 'Roboto', sans-serif;
  font-size: 0.68rem;
  color: var(--lightRed);
  font-weight:bold;
  letter-spacing:0.01em;
  margin-left: 0.8rem;
}

@media (max-width: 392px) {
  .saving-sm{
    font-size: 0.64rem;
    margin-left: 0.5rem;
  }
}

@media (max-width: 376px) {
  .saving-sm{
    font-size: 0.55rem;
    margin-left: 0.5rem;
  }
}


.brand-div-sm{
  // padding-top:0.2rem;
  // padding-bottom:0.25rem;
  margin-left:0.8rem;
}

.brand-img-sm{
  border-radius: 2px;
  // transform: scaleY(0.8);
  transform: scaleX(1) scaleY(0.8);
  margin-left:2.5rem;
}

.shipping-div-sm{
  position: relative;
  // margin-top: 0.2rem;
  margin-left:0.8rem;
  top:-0.1rem;
}

.shipping-icon-sm{
     color: var(--lightGreen);
     font-size: 0.8rem;
}

.available-sm{
  font-family: 'Radio Canada', sans-serif;
  font-size: 0.8rem;
  letter-spacing:0.01em;
  color: var(--pureBlack);
  margin-left:0.3rem;

  .country-sm{
    font-family: 'Radio Canada', sans-serif;
    font-weight:bold;
  }
 
}

.discount-perc-sm{
  position: absolute;
  top:-2.5%;
  bottom:4.5%;
  z-index:1111;
  padding-left: 0.2rem;
  

  .discount-value-sm{
    background: var(--brightRed);
    border-radius:0.2rem;
    padding: 0.2rem 0.2rem;
    font-family: 'Ubuntu', sans-serif;
    color: #ffffff;
    font-size: 0.6rem;
  }

  .minus-sm{
    letter-spacing:0.1em;
  }
}

.material-div-sm{
  position: relative;
  margin-left:0.8rem;
  top:-0.65rem;

  .material-sm{
    // margin-left: 1.5rem;
    // font-family: 'Karla', sans-serif;
    font-family: 'Roboto', sans-serif;
    // background: linear-gradient(90deg, #808080, #808080, #909090);
    // background: linear-gradient(90deg, #606060, #808080);
    color: var(--lightBlack);
    background-color: #d3d3d3;
    padding:0.15rem 0.4rem;
    border-radius:0.2rem;
    font-size:0.7rem;
    // letter-spacing:0.01em;
  }
}

`;