import React,{useState,useEffect} from 'react';
import { Item } from './Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export const FooterDiv = () => {

    const info_01 = ['Help','Log In','FAQ'];
    const info_02 = ['Privacy Policy','Terms of Service','Report Abuse'];
    const info_03 = ['Company Details','Contact Us'];

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';


  return (

    <React.Fragment>
    <div className={containerClass}>
            <div className='row'>

            <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 col-xl-12 footer">

            <div className="row">

            <div className="col-6 mx-auto col-md-2 col-lg-2 col-sm-6 col-xl-3 info-01 text-center">

            <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items">
              {
                 info_01.map((item,index)=><Item key={index} item={item}/>)
              }
          </div>  

          </div> {/*info-01*/}

          <div className="col-6 mx-auto col-md-3 col-lg-3 col-sm-6 col-xl-3 info-02 text-center">

            <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items">
              {
                 info_02.map((item,index)=><Item key={index} item={item}/>)
              }
          </div>  

          </div> {/*info-02*/}

          <div className="col-6 mx-auto col-md-3 col-lg-3 col-sm-6 col-xl-3 info-03 text-center">

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items">
  {
     info_03.map((item,index)=><Item key={index} item={item}/>)
  }
</div>  

</div> {/*info-03*/}

<div className="col-6 mx-auto col-md-4 col-lg-4 col-sm-6 col-xl-3 social-media text-center">

                       <a href="https://twitter.com/joyscanner_"><FontAwesomeIcon icon={faTwitter} className="icon"/></a>
                       <a href="https://web.facebook.com/profile.php?id=61554773626707"><FontAwesomeIcon icon={faFacebook} className="icon"/></a>
                       <a href="https://www.instagram.com/joyscanner/"><FontAwesomeIcon icon={faInstagram} className="icon"/></a>
                       <a href="https://www.youtube.com/@joyscanner"><FontAwesomeIcon icon={faYoutube} className="icon"/></a>
  

</div> {/*social-media*/}


       </div>

            </div>

            <div className='trademark text-center'>
                <h1 className='moto'>Compare and buy best adult toys with Joyscanner</h1>
                <span className='company'>© Joyscanner 2022 – 2024</span>
            </div>

            </div>
            </div>
            </React.Fragment>
  )
}