import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import {Item} from './Item';
import { NewItem } from './NewItem';

export const Loading = () => {

  const items = [1,2,3,4];

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth >= 1024);
  };
 

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const containerClass = isWideScreen ? 'container' : 'container-fluid';
  
     return (
    <LoadingContainer>
         <div className={containerClass}>
            <div className='row'>

            { <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-xl d-none d-xl-block">
            <div className="row">
            { items && items.slice(0,4).map((index) => <Item key={index} />) }
             </div>
            </div> }

            { <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-md d-none d-md-block d-xl-none">
            <div className="row">
            { items && items.slice(0,3).map((index) => <Item key={index} />) }
             </div>
            </div> }


            { <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-sm d-md-none">
            <div className="row mt-2">
            { items && items.slice(0,4).map((index) => <NewItem key={index} />) }
             </div>
            </div> }
                   
            </div>
            </div>
    </LoadingContainer>
  )
}

const LoadingContainer = styledComponents.div`

background-color: #ffffff;

.item-loading{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scaleX(0.9) scaleY(0.95);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    border-radius: 10px 10px 0 0;
   }

.card{
    background: transparent;
    border: none;
  }

  .card-footer-loading{
    padding: 0.5rem 1.5rem 0 1rem;
    background-color: rgb(245, 245, 245);
    padding-bottom: 0.6rem;
  }

.img-container-loading{
    background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    border: none;
  }

  .card-img-top-loading{
    border-radius: 10px;
    transform: scaleX(0.8) scaleY(1);
  }

  
`;
