import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ToyIndex } from './components/ToyIndex';
import { ToyOffer } from './components/offer-page/ToyOffer';
import { ToyRelease } from './components/new-release-page/ToyRelease';
import { ToySelling } from './components/best-selling-page/ToySelling';
import { ToySearch } from './components/search/ToySearch';
import { BrandPage } from './components/brands/BrandPage';
import { ToyBrand } from './components/brand-page/ToyBrand';
import { DealPage } from './components/deals/DealPage';
import { ToyUnder25 } from './components/under-25-apge/ToyUnder25';
import { Default } from './components/default/Default';
import { DeepLink } from './components/redirect/DeepLink';

const App = () => {
  return (
   <React.Fragment>

     <Routes>

     <Route path="/" element={<ToyIndex/>}/>

     <Route path="/collections/new-offers" element={<ToyOffer/>}/>

     <Route path="/collections/new-releases" element={<ToyRelease/>}/>

     <Route path="/collections/best-selling" element={<ToySelling/>}/>

     <Route path="/collections/under-25" element={<ToyUnder25/>}/>

     <Route path="/collections/:brand" element={<ToyBrand/>}/>

     <Route path="/s/:forValue/:ctgValue" element={<ToySearch/>}/>

     <Route path="/brands" element={<BrandPage/>}/>

     <Route path="/deals" element={<DealPage/>}/>

     <Route path="/product_deeplink/*" element={<DeepLink/>}/>

     <Route path='*' element={<Default/>}/>

     </Routes>

   </React.Fragment>
  );
}

export default App;
