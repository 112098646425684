import React,{useState,useEffect,useRef} from 'react';
import styledComponents from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import { MenuModal } from './MenuModal';
import { Link,useNavigate } from 'react-router-dom';

export const TopBar = () => {

  const menuDivRef = useRef(null);

  const [smallSize, setSmallSize] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();

  const redirectToHome=()=>{
    return navigate('/');
  }

  useEffect(() => {

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 390) {
        setSmallSize(true);
      } else {
        setSmallSize(false);
      }
    };

    // Initial call to set the image size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (menuDivRef.current && !menuDivRef.current.contains(event.target)) {
        setOpenMenu(false);
    }
   
  };


  useEffect(() => {
    
    // Attach event listener when the component mounts
    window.addEventListener('click', handleOutsideClick);

    // Detach event listener when the component unmounts
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (

    <TopBarContainer>

    <div className='container-fluid'>
    <div className='row'>
           
    <div className='col-xl-11 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar d-flex'>

    <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6 mx-auto logo text-start'>
    {(smallSize) ? <img onClick={()=>redirectToHome()} src='/logo.svg' alt="Toysacnner" width={'35rem'} height={'35rem'} className="logo-svg align-top"/>
    : <img onClick={()=>redirectToHome()} src='/logo.svg' alt="Toysacnner" width={'40rem'} height={'40rem'} className="logo-svg align-top"/> }
    <Link to={'/'}><label className='site-name'>Joyscanner</label></Link>
    </div>

    <div className='col-6 col-md-6 col-lg-6 col-xl-6 col-sm-6 mx-auto login-menu d-flex'>

    <div className='col-10 col-md-10 col-lg-10 col-xl-11 col-sm-11 mx-auto user text-end'>

   <a className='svg-text'>
    { (smallSize) ? <img src='/login.svg' alt="login" width={'40rem'} height={'28rem'} className="login-svg align-top mt-1"/>
    : <img src='/login.svg' alt="login" width={'40rem'} height={'28rem'} className="login-svg align-top"/> }
     { (smallSize) ? <label className="log-in mt-2 ps-1">Log in</label>
     : <label className="log-in ps-1">Log in</label> }
    </a>

    </div>

    <div ref={menuDivRef} className='col-2 col-md-2 col-lg-2 col-xl-1 col-sm-1 mx-auto bar text-center'>

    { (smallSize) ? <FontAwesomeIcon className="fa-bars icon mt-2" icon={faBars} onClick={()=>setOpenMenu(!openMenu)}/>
    : <FontAwesomeIcon className="fa-bars icon" icon={faBars} onClick={()=>setOpenMenu(!openMenu)}/>}

    </div>

       </div>
       
    </div>

    <div className='menu-bar-div'>
               { (openMenu) && <MenuModal/> }
      </div> 

     </div>
</div>

</TopBarContainer>
  )
}


const TopBarContainer = styledComponents.div`


@media (max-width: 1024px) {
  .top-bar {
    padding: 0 3.1rem;
  }
}

@media (min-width: 1025px) {
  .top-bar {
    padding: 0 3.6rem;
  }
}

@media (max-width: 912px) {
  .top-bar {
    padding: 0 0.9rem;
  }
}

@media (max-width: 600px) {
  .top-bar {
    padding: 0 0.6rem;
  }
}

.site-name{
   //font-family: 'Merriweather', serif;
  font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
    // font-weight: bold;
    color: var(--mainWhite);
    text-decoration: none;
    padding-top: 0.1rem;
    cursor:pointer;
}

@media (max-width: 390px) {
  .site-name{
    font-size: 1.3rem;
  }
}

.login-svg{
  cursor:pointer;
  margin-top:0.45rem;
}


.log-in{
  font-family: 'Merriweather', serif;
    font-size: 0.95rem;
    color: var(--mainWhite);
    cursor:pointer;
    margin-top: 0.6rem;
}

.svg-text{
    padding: 0.4rem 0.6rem 0.6rem 0.2rem;
    border-radius: 0.3rem;
    cursor:pointer;
    transition: background 0.3s ease;
    position:relative;

    &:hover{
      background: var(--lightGrey4);
      transition: background 0.3s ease;
    }
}

@media (min-width: 1400px) {
  .svg-text{
    left:0.4rem;
  }
}

@media (max-width: 1024px) {
  .svg-text{
    left:1rem;
  }
}

@media (max-width: 820px) {
  .svg-text{
    left:0.6rem;
  }
}

@media (max-width: 720px) {
  .svg-text{
    left:0;
  }
}


.fa-bars{
    font-size: 1.4rem;
    color: var(--mainWhite);
    margin-top: 0.65rem;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover{
      color: var(--lightGrey5);
      transition: color 0.3s ease;
      opacity:0.9;
    }
}

.logo-svg{
  cursor:pointer;
}


@media (max-width: 600px) {
  .login-menu{
    position:relative;
    right: 0.13rem;
  }
}

 
`;