import React,{useEffect,useState} from 'react';
import { OfferPage } from './OfferPage';
import styledComponents from 'styled-components';
import { TopToyBar } from '../top-bar/TopToyBar';
import { Footer } from '../footer/Footer';
import { Helmet } from 'react-helmet';

export const ToyOffer = () => {

  const [pageTitle, setPageTitle] = useState(`New Offers Sex Toys - Best Offers in 2024 - Joyscanner`);
  const [pageDescription, setPageDescription] = useState(`Discover the new offers Sex Toys in Best Sellers. Find the top 100 best offers Sex Toys in Joyscanner.`);

  useEffect(()=>{
    const title = `New Offers Sex Toys - Best Offers in 2024 - Joyscanner`;
    document.title = title;
 },[]);

  return (
      <ToyOfferContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>


    <div className='container-fluid'>
            <div className='row'>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-toy-bar-div'>
           <TopToyBar/>
           </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto offer-page'>
                <OfferPage/>
            </div>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
             <Footer/>
           </div>

            </div>
            </div>
            </ToyOfferContainer>
  )
}

const ToyOfferContainer = styledComponents.div`

background-color: #ffffff;

.top-toy-bar-div{
    margin:0;
    padding:0;  
    background: var(--lightPink);
  }

  @media (max-width: 912px) {
    .top-toy-bar-div{
      padding: 0 1rem;
    }
  }
  
  @media (max-width: 820px) {
    .top-toy-bar-div{
      padding: 0 0.3rem;
    }
  }

  .search-bar-div{
    padding: 0 6.7rem;
  }
  
  @media (max-width: 1368px) {
    .search-bar-div{
      padding: 0 2.2rem;
    }
  }
  
  @media (max-width: 912px) {
    .search-bar-div{
      padding: 0 2.2rem;
    }
  }
  
  @media (max-width: 940px) {
    .search-bar-div{
      padding: 0 0.8rem;
    }
  }
  
  @media (max-width: 600px) {
    .search-bar-div{
      padding: 0 0.5rem;
    }
  }
  

  .offer-page{
    margin:0;
    padding:0 6.7rem;  
    background-color: #ffffff;
  }
  
  @media (max-width: 1368px) {
    .offer-page{
      padding:0 2.2rem;  
    }
  }
  
  @media (max-width: 912px) {
    .offer-page{
      padding:0 0.85rem;  
    }
  }
  
  @media (max-width: 820px) {
    .offer-page{
      padding:0 0.1rem;  
    }
  }

  .top-bar-div{
    margin:0;
    padding:0;  
    margin-top: 0.5rem;
  }
  
  
  @media (min-width: 1024px) {
    .top-bar-div{
      margin-top: 2rem;
    }
  }

  .footer-div{
    margin:3.5rem 0 0 0;
    padding:0;  
    background: var(--lightPink);
  }

`;