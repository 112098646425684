import React from 'react'
import styledComponents from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const NewItem = () => {


  return (
     <ItemContainer>

<div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto item-sm-loading d-flex'>

<div className='col-xl-5 col-5 col-md-5 col-lg-5 col-sm-5 mx-auto item-img-sm-loading'>

<div className="img-container-sm-loading">
           <div className="card-img-top-sm-loading" style={{width:'100%',height:'12rem'}}/> 
    </div>

</div>

<div className='col-xl-7 col-7 col-md-7 col-lg-7 col-sm-7  mx-auto item-detail-sm-loading'>
            <Skeleton className="loading-sk mt-4" count={1} baseColor="var(--lightGrey6)" width={'90%'}/> 
            <Skeleton className="loading-sk mt-3" count={1} baseColor="var(--lightGrey6)" width={'80%'}/> 
            <Skeleton className="loading-sk mt-2" count={1} baseColor="var(--lightGrey6)" width={'90%'}/> 
            <Skeleton className="loading-sk mt-5" count={1} baseColor="var(--lightGrey6)" width={'80%'}/> 
</div>

  </div>

     </ItemContainer>
  )
}

const ItemContainer = styledComponents.div`

background-color: #ffffff;
margin: 0.5rem 0;

.item-sm-loading{
  position:relative;
  padding:0 !important;
  margin:0 !important;
  // transform: scale(0.9);
  cursor:pointer;
  transition:0.5s all;
  text-decoration:none;
  border: 1px solid #ccc;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  // transition: box-shadow 0.3s ease;
  border-radius: 0 5px 5px 0;

  // &:hover {
  //   box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  // }
 }

 .item-detail-sm-loading{
  // background-color: rgb(245, 245, 245);
  background: linear-gradient(to bottom, #FFFFFF, #ECECEC);
 }

 .img-container-sm-loading{
  background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
  padding: 10px 15px;
  // border-radius: 10px 10px 0 0;
  border: none;
}

.card-img-top-sm-loading{
  border-radius: 3px;
  transform: scaleY(1);
  transform: scaleX(1);
}

.loading-sk{
    margin-left:0.8rem;
}


`;