import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { ItemSm } from './ItemSm';

export const LoadingSm = () => {

  const items = [1,2,3,4,5,6];

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const containerClass = isWideScreen ? 'container' : 'container-fluid';
  
     return (
    <ToyListContainer>
         <div className={containerClass}>
            <div className='row'>

            { <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 item-list-loading">
            <div className="row overflow-auto flex-nowrap">
            { items && items.map((index) => <ItemSm key={index}/>) }
             </div>
            </div> }
                    
            </div>
            </div>
    </ToyListContainer>
  )
}

const ToyListContainer = styledComponents.div`

background-color: #ffffff;

.row::-webkit-scrollbar {
  display: none;
}


.item-loading{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scaleX(0.95) scaleY(0.95);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    border-radius: 5px 5px 0 0;
   }

   @media (max-width: 1368px) {
    .item{
      width: auto;
    }
  }

.card{
    background: transparent;
    border: none;
  }

  .card-footer{
    padding: 0.8rem 1.2rem 0 0.8rem;
    background-color: rgb(245, 245, 245);
    //  margin-bottom: -1.6rem;
    height: 8.8rem;

  }

  @media (max-width: 1024px) {
    .card-footer{
      padding: 0.4rem 1.4rem 0 0.8rem;
    }
  }

  @media (max-width: 600px) {
    .card-footer{
      height: 7.4rem;
    }
  }

.img-container-loading{
    background: linear-gradient(135deg, white 40%, #e0e0e0 60%);
    // padding: 20px 30px;
    border-radius: 5px 5px 0 0;
    border: none;
  }

  .card-img-top-loading{
    border-radius: 5px;
    transform: scaleX(0.85) scaleY(1);
  }

  
`;