import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const Loading = () => {

  return (
    <React.Fragment>
         <div className='col-xl-3 col-12 col-md-4 col-lg-3 col-sm-12 mx-auto brand-loading'>

            <div className="card">

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12s mx-auto name-img-loading text-center'>
                     
            {/* <div className='col-xl-6 col-6 col-md-6 col-lg-6 col-sm-6 mx-auto brand-name text-start'>
                <h4 className='name'>{brand.brandName}</h4>
             </div> */}

             {/* <div className='col-xl-6 col-6 col-md-6 col-lg-6 col-sm-6 mx-auto brand-img-div text-end'> */}

             <div className="img-container-loading">
             <Skeleton className="brand-img-loading" style={{width:'6rem',height:'2.5rem'}}/> 
             </div>

             {/* </div> */}

             </div>

         </div>
         </div>
    </React.Fragment>
  )
}