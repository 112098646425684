import React from 'react';
import styledComponents from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const ShowMoreLoading = ({pageNum,numOfItems}) => {

  const totalItems = numOfItems;
  const itemsPerPage = 16;

  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (event) => {
     
        event.preventDefault();
  
  };


  return (

    <MoreContainer>


    <div className='container'>
    <div className='row'>

        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto total-count text-center mt-4'>
             { (pageNum) && <span className='total'>Showing <span className='current-page'>{pageNum}</span> - {numberOfPages} of {totalItems} total</span> }
        </div>

        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto line-div text-center'>
        <label className='line'></label>
        </div>

        <div className='col-12 col-md-12 col-lg-12 col-xl-12 col-sm-12 mx-auto total-count text-center mt-2'>
            <a href="#" className='show-more' onClick={handleClick}> <CircularProgress className="load-icon" size={15} /> </a>
        </div>
        
        </div>
        </div>

        </MoreContainer>
  )
}

const MoreContainer = styledComponents.div`


.total{
    position: relative;
    font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    color: var(--lightGrey2);

    // .current-page{
    //   color: var(--lightBlack);
    // }
}

.line {
    position: relative;
    // margin-top:0.5rem;
    top:-0.5rem;
    width: 200px;
    height: 2px;
    background-color: var(--lightGrey); 
  }

 .show-more{

border:none;
border-radius:1.5rem;
padding: 0.5rem 8.8rem 0.4rem 8.8rem;
font-size:1rem;
outline:none;
background: var(--pureBlack);
color: var(--pureBlack);
border: 1px solid var(--pureBlack);
cursor:pointer;
// font-family: 'Karla', sans-serif;
font-family: 'Open Sans', sans-serif; 
font-weight:bold;
text-transform: uppercase;
letter-spacing:0.05rem;
transition: all 0.6s ease;
text-decoration:none;

} 

 .load-icon{
    color: #ffffff;
    position: relative;
}


`;