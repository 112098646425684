import React from 'react'
import styledComponents from 'styled-components'

export const BrandTitle = () => {
  return (
    <BrandContainer>
    <h1 className='brand-title'>The Best Sex Toys from Trusted Sellers, One Simple Search</h1>
    </BrandContainer>
  )
}

const BrandContainer = styledComponents.div`

.brand-title{
    color: var(--mainWhite);
    font-family: 'Ubuntu', sans-serif;
    // font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    margin-left:0.5rem;
    // letter-spacing:0.01em;
}

`;