import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { Footer } from '../footer/Footer';
import { TopToyBar } from '../top-bar/TopToyBar';
import { DealListPage } from './DealListPage';
import axios from 'axios';
import { Loading } from './Loading';
import { Helmet } from 'react-helmet';

export const DealPage = () => {

  const [pageTitle, setPageTitle] = useState(`New Sex Toy Deals - Joyscanner`);
  const [pageDescription, setPageDescription] = useState(`Discover the new Sex Toy Deals. Find the top 100 new Sex Toy Deals in Joyscanner.`);

    const [deals,setDeals]=useState([]);

    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(()=>{
      const title = `New Sex Toy Deals - Joyscanner`;
      document.title = title;
   },[]);

    useEffect(()=>{
      window.scrollTo(0, 0);
    },[]);

    useEffect(()=>{

      const deal_url=process.env.REACT_APP_DEAL;
  
      setLoading(true);
  
      if(!isOnline)
      return;
    
  
      axios.get(`${deal_url}/list`,{
          headers:{
          //    'Authorization':'bearer '.concat(token)
            }
      }).then(res=>{
          setDeals(res.data);
          setLoading(false);
      }).catch(error=>{
          console.log(error);
          setLoading(false);
      });
  
    },[isOnline]);

  return (
     <DealPageContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>

         <div className='container-fluid'>
            <div className='row'>

            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-toy-bar-div'>
           <TopToyBar/>
           </div>

            { (!loading) && deals.map(deal=>{
                { return (deal) && <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto deal-page'>
                   <DealListPage d={deal}/> 
                 </div> }
            }) }

          { (loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 loading-div">
            <Loading/>
            </div> } 

         <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
             <Footer/>
           </div>

            </div>
            </div>
     </DealPageContainer>
  )
}

const DealPageContainer = styledComponents.div`

background-color: #ffffff;

.top-toy-bar-div{
  margin:0;
  padding:0;  
  background: var(--lightPink);
}

@media (max-width: 912px) {
  .top-toy-bar-div{
    padding: 0 1rem;
  }
}

@media (max-width: 820px) {
  .top-toy-bar-div{
    padding: 0 0.3rem;
  }
}

.search-bar-div{
  padding: 0 6.7rem;
}

@media (max-width: 1368px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 912px) {
  .search-bar-div{
    padding: 0 2.2rem;
  }
}

@media (max-width: 940px) {
  .search-bar-div{
    padding: 0 0.8rem;
  }
}

@media (max-width: 600px) {
  .search-bar-div{
    padding: 0 0.5rem;
  }
}

.deal-page{
  margin:0;
  padding:0 6.7rem;  
  background-color: #ffffff;
}

@media (max-width: 1368px) {
  .deal-page{
    padding:0 2.2rem;  
  }
}

@media (max-width: 912px) {
  .deal-page{
    padding:0 0.85rem;  
  }
}

@media (max-width: 820px) {
  .deal-page{
    padding:0 0.1rem;  
  }
}

  .top-bar-div{
    margin:0;
    padding:0;  
    margin-top: 0.5rem;
  }
  
  
  @media (min-width: 1024px) {
    .top-bar-div{
      margin-top: 2rem;
    }
  }

  .footer-div{
    margin:3.5rem 0 0 0;
    padding:0;  
    background: var(--lightPink);
  }


  

`;