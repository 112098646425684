import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { Brand } from './Brand';
import {Loading} from './Loading';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export const BrandList = () => {

    const [pageTitle, setPageTitle] = useState(`Top Sex Toy Brands - Joyscanner`);
    const [pageDescription, setPageDescription] = useState(`Discover the top Sex Toy Brands. Find the top 100 most popular Sex Toy Brands in Joyscanner.`);

    const [brands,setBrands]= useState([]);

    const loading_list=[1,2,3,4,5,6,7,8];
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

  useEffect(()=>{
    const title = `Top Sex Toy Brands - Joyscanner`;
    document.title = title;
 },[]);

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
        setIsWideScreen(window.innerWidth >= 1024);
      };

      useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

      useEffect(()=>{

        const brand_url=process.env.REACT_APP_BRAND;
    
        setLoading(true);
    
        if(!isOnline)
        return;
    
    
        axios.get(`${brand_url}/list`,{
            headers:{
            //    'Authorization':'bearer '.concat(token)
              }
        }).then(res=>{
            setBrands(res.data);
            setLoading(false);
        }).catch(error=>{
            console.log(error);
            setLoading(false);
        });
    
      },[isOnline]);
    
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';

  return (
      <BrandListContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>

  <div className={containerClass}>
    <div className='row'>

    <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 brand-title-div">
       <h2 className='brand-title'>Brands</h2>
     </div>
     
    { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 brand-list">
            <div className="row">
            { brands && brands.map((brand) => <Brand key={brand.brandId} brand={brand}/>) }
             </div>
            </div> }

{ (loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 brand-list d-none d-lg-block">
     <div className="row">
     { loading_list.map((index) => <Loading key={index}/>) }
     </div>
     </div> }       

     { (loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 col-xl-12 brand-list d-lg-none">
     <div className="row">
     { loading_list.slice(0,6).map((index) => <Loading key={index}/>) }
     </div>
     </div> }

            
        </div>
        </div>
        </BrandListContainer>
  )
}

const BrandListContainer = styledComponents.div`

.brand-list{
    padding: 0 1.65rem;
}

@media (max-width: 600px) {
    .brand-list{
        padding: 0 1.2rem;
    }
}

@media (max-width: 376px) {
    .brand-list{
        padding: 0 1rem;
    }
}


.brand{
    position:relative;
    padding:0 !important;
    margin:0.8rem 0!important;
    transform: scaleX(0.95);
}

.brand-loading{
    position:relative;
    padding:0 !important;
    margin:0.8rem 0!important;
    transform: scaleX(0.95);
}

@media (max-width: 600px) {
    .brand{
        margin:0.6rem 0!important;
    }
}

.name-img{
    position:relative;
    padding:1.2rem 0;
    border-radius:0.4rem;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    cursor:pointer;
    text-decoration:none;

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }
}

.name-img-loading{
    position:relative;
    padding:1.2rem 0;
    border-radius:0.4rem;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
    cursor:pointer;
    text-decoration:none;

}

@media (max-width: 600px) {
    .name-img{
        padding:1.2rem 0;
    }
}

.brand-name{
    padding:0 !important;
    margin:0 !important;
}

.brand-img-div{
    padding:0 !important;
    margin:0 !important;
}

.brand-name .name{
    position:relative;
    font-family: 'Roboto', sans-serif;
    font-size:1.1rem;
    margin-left:0.8rem;
    top:0.3rem;
    cursor:pointer;
    color: var(--pureBlack);
}

@media (max-width: 600px) {
    .brand-name .name{
        margin-left:1.2rem;
    }
}

// .img-container{
//     transform: scaleX(1.5) scaleY(1.2);
// }

.brand-img-div .brand-img{
    margin-right:0.8rem;
    border-radius:0.2rem;
    cursor:pointer;
}

@media (max-width: 600px) {
    .brand-img-div .brand-img{
        margin-right:1rem;
    }
}


.brand-title{
    color: var(--pureBlack);
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5rem;
    margin-left:0.55rem;
}

@media (max-width: 600px) {
    .brand-title{
        margin-left:0.2rem;
    }
}

@media (max-width: 376px) {
    .brand-title{
        margin-left:0.2rem;
    }
}

// .brand-list-loading{
//     padding:0 !important;
//     margin:0 !important;
// }

.brand-name-div{
    height: 2.5rem;
}

.brand-name{
    font-size:1.3rem;
    position: relative;
    top: 0.3rem;
    // font-family: 'Roboto', sans-serif;
    font-family: 'Karla', sans-serif;
}

`;