import styledComponents from "styled-components";

export const MoreButton=styledComponents.button`

border:none;
border-radius:1.5rem;
padding: 0.5rem 6rem 0.4rem 6rem;
font-size:1rem;
outline:none;
background: #ffffff;
color: var(--pureBlack);
border: 1px solid var(--pureBlack);
cursor:pointer;
// font-family: 'Karla', sans-serif;
font-family: 'Open Sans', sans-serif; 
font-weight:bold;
text-transform: uppercase;
letter-spacing:0.05rem;
transition: all 0.6s ease;

&:hover{
    background: var(--lightBlack);
    color: #ffffff;
}

`;