import React,{useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router';
import { SearchBar } from '../search-bar/SearchBar';
import { SearchBarSm } from '../search-bar/SearchBarSm';
import { TopBar } from './TopBar';

export const TopToyBar = () => {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const { forValue, ctgValue } = useParams();
  const priceValue = queryParams.get('price');

    const [selectedForOption, setSelectedForOption] = useState('');
    const [selectedCtgOption, setSelectedCtgOption] = useState('');
    const [selectedPriceOption, setSelectedPriceOption] = useState('');


   const getForValue=(f)=>{
    switch(f.toLowerCase()){
      case "for-her":
        return "her";
      case "for-him":
        return "him";
      case "for-couple":
        return "couple";
    }
   }

   const getPriceValue=(p)=>{
    switch(p.toLowerCase()){
      case "u1":
        return "Under $25";
      case "u2":
        return "$25 - $50";
      case "u3":
          return "$50 - $100";  
      case "u4":
            return "$100 - $200";  
      case "u5":
            return "$200 & above";  
      case "u6":
            return "any price";  
      default:
        return "any price"; 
   }
  }

  const getCtgValue=(c)=>{
    return c.replaceAll("-"," ");
}  

useEffect(()=>{
       (forValue) && setSelectedForOption(getForValue(forValue));
       (ctgValue) && setSelectedCtgOption(getCtgValue(ctgValue));
       (priceValue) && setSelectedPriceOption(getPriceValue(priceValue));
},[forValue,ctgValue,priceValue]);

  return (
    <React.Fragment>
         <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar-div'>
                <TopBar/>
            </div>
            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto search-bar-div mt-3 d-none d-lg-block py-3'>
                <SearchBar selectedForOption={selectedForOption} setSelectedForOption={setSelectedForOption}
                selectedCtgOption={selectedCtgOption} setSelectedCtgOption={setSelectedCtgOption} selectedPriceOption={selectedPriceOption} setSelectedPriceOption={setSelectedPriceOption}/>
            </div>
            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto search-bar-div mt-4 d-block d-lg-none py-1'>
                <SearchBarSm selectedForOption={selectedForOption} setSelectedForOption={setSelectedForOption}
                selectedCtgOption={selectedCtgOption} setSelectedCtgOption={setSelectedCtgOption} selectedPriceOption={selectedPriceOption} setSelectedPriceOption={setSelectedPriceOption}/>
            </div>
    </React.Fragment>
  )
}
