import React,{useRef,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { PageTitle } from './PageTitle';

export const FilterSm = ({selectedValue,clickedOption,setClickedOption,result,category}) => {

    const optionDivRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (optionDivRef.current && !optionDivRef.current.contains(event.target)) {
            setClickedOption(false);
        }
      };
    
    
      useEffect(() => {
        
        window.addEventListener('click', handleOutsideClick);
    
        return () => {
          window.removeEventListener('click', handleOutsideClick);
        };
      }, []);

  return (
    <div className='container d-md-none'>
      <div className='row'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title-page-div'>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto results-div text-start'>
       <PageTitle title={result} ctg={category}/>
     </div>

    
     <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto sort-div text-end'>

<div className='sort'>
<span ref={optionDivRef} className='sort-label-sm' onClick={()=>{setClickedOption(!clickedOption)}}>
      <span className='sort-by px-1'>Sort by: </span><span className='sort-value'>{selectedValue}</span><FontAwesomeIcon icon={faAngleDown} className="angle-down ps-2" />
      </span>

</div>

  </div>

      </div>

      </div>
      </div>
  )
}
