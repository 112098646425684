import React,{useState,useEffect} from 'react';
import { NotFound } from './NotFound';
import styledComponents from 'styled-components';
import { Helmet } from 'react-helmet';

export const Default = () => {

  const [pageTitle, setPageTitle] = useState(`Not found - Joyscanner`);
  const [pageDescription, setPageDescription] = useState(`Online shopping for Pleasure & Wellness from a great selection of Vibrators, Anal Sex Toys, Penis Rings, Dildos, Male Masturbators, Pumps & Enlargers & more at everyday low prices from trusted brands and sellers.`);

  useEffect(()=>{
    const title = `Not found - Joyscanner`;
    document.title = title;
 },[]);


  return (
      <DefaultContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       </Helmet>

    <div className='container-fluid'>
            <div className='row'>

            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 default">
                        <NotFound/>
            </div>

            </div>
            </div>
            </DefaultContainer>
  )
}

const DefaultContainer = styledComponents.div`
.default{
    background: #ffffff;
    height: 100vh;
}
`;