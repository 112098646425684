import React from 'react'
import styledComponents from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const MenuModal = () => {

    const redirectTo=()=>{
        return `/s/for-her/lingerie?price=u6`;
    }
  return (
    <MenuContainer>
                <div className="col-8 col-md-4 col-lg-4 col-xl-2 col-sm-4 menu-div">
                    

                <Link to={`/brands`}> <div className="col-12 col-md-12 col-lg-12 col-sm-12 brands menu-col text-start">
            <label className='menu-name px-2 my-1'> <FontAwesomeIcon icon={faPuzzlePiece} className="menu-icon" /> <span className='ps-3'>Brands</span></label>
         </div> </Link>
         
               <Link to={`/deals`}> <div className="col-12 col-md-12 col-lg-12 col-sm-12 deals menu-col text-start">
             <label className='menu-name px-2 my-1'> <FontAwesomeIcon icon={faTag} className="menu-icon" /> <span className='ps-3'>Deals</span></label>
         </div> </Link>

         <Link to={redirectTo()}> <div className="col-12 col-md-12 col-lg-12 col-sm-12 lingerie menu-col text-start">
            <label className='menu-name px-2 my-1'> <FontAwesomeIcon icon={faShoppingBag} className="menu-icon" /> <span className='ps-3'>Lingerie</span></label>
         </div> </Link>

             <hr className='line'/>

         <div className="col-12 col-md-12 col-lg-12 col-sm-12 brands menu-col text-start">
            <label className='help-name px-2 my-1'> <FontAwesomeIcon icon={faQuestionCircle} className="help-icon" /> <span className='ps-3'>Help</span></label>
         </div>   


                </div>
    </MenuContainer>
  )
}

const MenuContainer=styledComponents.div`

position:relative;

.menu-div{
    position:absolute;
    background-color: #ffffff;
    // left:75%;
    // right:0;
    // bottom:0;
    z-index:1112;
    border-radius:0.6rem;
    padding: 0.5rem 0; border: 1px solid #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

}

@media screen and (min-width: 1025px) {
    .menu-div {
        left: 75%;
        right: 0;
    }
}

@media screen and (max-width: 1024px) {
    .menu-div {
        left: 60%;
        right: 0;
    }
}

@media screen and (max-width: 912px) {
    .menu-div {
        left: 64%;
        right: 0;
    }
}

@media screen and (max-width: 750px) {
    .menu-div {
        left: 33%;
        right: 0;
    }
}

.menu-col{
     cursor: pointer;
     padding: 0.5rem 0;
     padding-left: 1rem;
     transition: background-color 0.3s ease;

    &:hover{
        background-color: var(--lightGrey5);
        transition: background-color 0.3s ease;
    }
}


.menu-name{
    font-family: 'Karla', sans-serif;
    color: var(--pureBlack);
    font-size:1rem;
    cursor: pointer;
}

.help-name{
    font-family: 'Karla', sans-serif;
    color: var(--lightGrey2);
    font-size:1rem;
    cursor: pointer;
}

.menu-icon{
    color: var(--lightBlue2);
    font-size:0.9rem;
    cursor: pointer;
}

.help-icon{
    color: var(--lightGreen2);
    font-size:0.9rem;
    cursor: pointer;
}

.line{
    color: var(--lightGrey2);
}

`;