import React,{useState,useEffect} from 'react';
import { Modal } from './Modal';
import styledComponents from 'styled-components';
import { ModalSm } from './ModalSm';
import { Helmet } from 'react-helmet';

export const DeepLink = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Redirecting to requested website...`);
  const [pageDescription, setPageDescription] = useState(`Online shopping for Pleasure & Wellness from a great selection of Vibrators, Anal Sex Toys, Penis Rings, Dildos, Male Masturbators, Pumps & Enlargers & more at everyday low prices from trusted brands and sellers.`);

  useEffect(()=>{
    const title = `Redirecting to requested website...`;
    document.title = title;
 },[]);

  return (
    <DeepContainer>

       <Helmet>
       <title>{pageTitle}</title>
       <meta name="description" content={pageDescription} />
       <link rel="canonical" href={canonicalUrl} />
       </Helmet>

    <div className='container-fluid'>
            <div className='row'>

            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 redirect d-none d-md-block">
                        <Modal/>
            </div>

            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 redirect-sm d-md-none">
                       <ModalSm/>
            </div>

            </div>
            </div>
            </DeepContainer>
  )
}

const DeepContainer = styledComponents.div`
.redirect{
    background: #ffffff;
    height: 100vh;
    padding: 0 25rem;
}

@media (max-width: 1368px) {
  .redirect{
    padding: 0 20rem;
  }
}

@media (max-width: 1024px) {
  .redirect{
    padding: 0 8rem;
  }
}

@media (max-width: 912px) {
  .redirect{
    padding: 0 5rem;
  }
}

@media (max-width: 820px) {
  .redirect{
    padding: 0 3rem;
  }
}

.redirect-sm{
  background: #ffffff;
  height: 100vh;
}

`;