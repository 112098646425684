import React,{useState,useEffect} from 'react'
import { Title } from '../offers/Title';
import styledComponents from 'styled-components'
import { ReleaseList } from './ReleaseList';

export const Release = () => {

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';

  return (
      <ReleaseContainer>
    <div className={containerClass}>
    <div className='row'>
           
    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title-div'>
       <Title title={"Hot New Releases"} page={'new-releases'}/>
    </div>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto release-list-div'>
         <ReleaseList/>
    </div>

    </div>
    </div>
    </ReleaseContainer>
  )
}

const ReleaseContainer = styledComponents.div`

.title-div{
  padding:1.5rem 0 0 0;
}

.release-list-div{
  margin-top:2rem;
}

`;