import React,{useState,useEffect} from 'react'
import { Title } from './Title';
import styledComponents from 'styled-components'
import { ToyList } from './ToyList';

export const TopRated = () => {

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    
      const containerClass = isWideScreen ? 'container' : 'container-fluid';

  return (
      <ReleaseContainer>
    <div className={containerClass}>
    <div className='row'>
           
    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title-div'>
       <Title title={"Treanding Right Now"}/>
    </div>

    <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-list-div'>
         <ToyList/>
    </div>

    </div>
    </div>
    </ReleaseContainer>
  )
}

const ReleaseContainer = styledComponents.div`

background-color: #ffffff;

.title-div{
  padding:1.5rem 0 0 0;
}

.top-list-div{
  margin-top:2.2rem;
  padding: 0 1.45rem;
}

@media (max-width: 1368px) {
  .top-list-div{
    padding:0 1.35rem;  
  }
}

@media (max-width: 912px) {
  .top-list-div{
    padding:0 1.25rem;  
  }
}

@media (max-width: 600px) {
  .top-list-div{
    padding:0 0.4rem;  
  }
}

`;