import React from 'react'
import styledComponents from 'styled-components'

export const BrandTitleSm = () => {
  return (
    <BrandContainer>
    <h1 className='brand-title'>The Best Sex Toys from Trusted Sellers, One Simple Search</h1>
    </BrandContainer>
  )
}

const BrandContainer = styledComponents.div`

.brand-title{
    color: var(--mainWhite);
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem;
    margin-left:0.5rem;
}

@media (min-width: 700px) {
    .brand-title {
      font-size: 1.5rem;
      // Additional styles for screens wider than 600px
    }
  }

`;